export const MainTextEnglish={
    INTEGRATIONS_TEXT:"Integrations",
    CONTACTUS_TEXT:"Contact Us",
    ANALYTICS_TEXT:"Analytics",
    TRACK_TEXT:"Track",
    MANAGE_TEXT:"Manage",
    PRODUCT_TEXT:"Product",
    RESOURCES_TEXT:"Resources",
    VIDEOS_TEXT:"Videos",
    CASESTUDY_TEXT:"Case Studies",
    SHIP_TEXT:"Ship",
    JOINNOW_TEXT:"Join Now",
    SIGNIN_TEXT:"Login",
    SIGNUP_TEXT:"Sign Up",
    ARABIC_TEXT:"Arabic",
    ENGLISH_TEXT:"English",
    LANGUAGE_TEXT:"Language",
    CARRIER_TEXT:"Carriers",
    HEROTEXT1_TEXT:"Seamless",
    SHIPPING_TEXT:"Shipping",
    HEROTEXT2_TEXT:"with your Choice of",
    HEROTEXT3_TEXT:"Partner",
    HEROTEXT5_TEXT:"Logistics",
    LETSTALS_TEXT:"Let's Talk",
    HEROTEXT4_TEXT:"Automate your shipping process while offering your customers a next level delivery experience. Easily connect to 70+ carriers using our API.",
    HEROBTN_TEXT :"Start Now Free",
    WORKTEXT1_TEXT :"How It Works",
    WORKTEXT2_TEXT :"The Fastest, most Effective",
    WORKTEXT3_TEXT :"way to Ship Products",
    WORKTEXT4_TEXT :"Import orders",
    WORKTEXT5_TEXT :"Import orders from 100+ selling channels, marketplaces, ERPs, CRMs, or IMSs with shipping configurations.",
    WORKTEXT6_TEXT :"Manage Shipping",
    WORKTEXT7_TEXT :"Use our assign-based integrated workflow to manage your orders with multiple couriers.",
    WORKTEXT8_TEXT :"Print Labels",
    WORKTEXT9_TEXT :"Access orders from Shipra Dashboard for single or Bulk Label printing and handover the associated courier pickup driver.",
    WORKTEXT10_TEXT :"Track orders",
    WORKTEXT11_TEXT :"You will be notified of your order's tracking information immediately after it is shipped and delivered.",
    ESTABLISHEDTEXT1_TEXT:"Established",
    ESTABLISHEDTEXT2_TEXT:"Trusted Carriers",
    ESTABLISHEDTEXT3_TEXT:" Shipments Annually",
    ESTABLISHEDTEXT4_TEXT:"Shipra Users",
    FEATURETEXT1_TEXT:"Features",
    FEATURETEXT2_TEXT:"Connect Variety of Carriers of your choice in seconds",
    FEATURETEXT3_TEXT:"Stress Free Courier Selection",
    FEATURETEXT4_TEXT:"20+ different couriers are integrated, assign shipments to courier of your choice. Choose courier that suits you the best.",
    FEATURETEXT5_TEXT:"Stress Free Courier Selection",
    FEATURETEXT6_TEXT:"20+ different couriers are integrated, assign shipments to courier of your choice. Choose courier that suits you the best.",
    FEATURETEXT7_TEXT:"Stress Free Courier Selection",
    FEATURETEXT8_TEXT:"20+ different couriers are integrated, assign shipments to courier of your choice. Choose courier that suits you the best.",
    FEATURETEXT9_TEXT:"Stress Free Courier Selection",
    FEATURETEXT10_TEXT:"20+ different couriers are integrated, assign shipments to courier of your choice. Choose courier that suits you the best.",
    SUBSCRIBETEXT1_TEXT:"Newsletter",
    SUBSCRIBETEXT2_TEXT:"Subscribe now for the latest Industrial updates!",
    SUBSCRIBETEXT3_TEXT:"We bring statistics reports on industrial best practices and also share insights on our latest integrations ventures.",
    SUBSCRIBETEXT4_TEXT:"Recipient Email",
    SUBSCRIBETEXT5_TEXT:"Subscribe Now",
    ABOUTTEXT1_TEXT:"About",
    ABOUTTEXT2_TEXT:"Start Shipra Online ",
    ABOUTTEXT3_TEXT:"For Free",
    ABOUTTEXT4_TEXT:"Shipra Dashboard",
    ABOUTTEXT5_TEXT:"Take your Store to the next level with ease of handling shipments order tracking and more.",
    ABOUTTEXT6_TEXT:"Manage Inventory",
    ABOUTTEXT7_TEXT:"Use Shipra to manage your inventory, including on-shelf, out-of-transit items and track your inventory levels.",
    ABOUTTEXT8_TEXT:"Live Support",
    ABOUTTEXT9_TEXT:"Get expert support whenever you need it, 24/7. Our team of experts is available to help you with any issue, big or small.",
    ABOUTTEXT10_TEXT:"Book a Meeting",
    FOOTERTEXT1_TEXT:"Shipra offers state of the art solution powered with integrations to manage daily orders and assigning multiple couriers across the region",
    FOOTERTEXT2_TEXT:"Company",
    FOOTERTEXT3_TEXT:"Product",
    FOOTERTEXT4_TEXT:"Contact",
    FOOTERTEXT5_TEXT:"Copyrights 2024",
    FOOTERTEXT7_TEXT:"Shipra.io ™ Al Qayid Al ABQARI Information Technology LLC",
    FOOTERTEXT6_TEXT:"Use cases",
    SOCIALSELLER_TEXT:"Social sellers",
    ONLINERETAILER_TEXT:"Online Retailers",
    OMNICHANNELBRANDS_TEXT:"Omnichannel Brands",
    LOGISTICSPL_TEXT:"Logistics & 3PL",
    ABOUTTEXT_TEXT:"About",
    FEATURETEXT_TEXT:"Features",
    CAREERTEXT_TEXT:"Career",
    PRICINGTEXT_TEXT:"Pricing",
    CONTACTUSTEXT_TEXT:"Contact US",
    PRIVACYTEXT_TEXT:"Privacy Policy",
    TERMSTEXT_TEXT:"Terms & Conditions",
    SUPPORTTEXT_TEXT:"Support Docs",
    BOOKDEMO_TEXT:"Book Demo",
    CONTACTHERO_TEXT1:"Get in touch with us",
    CONTACTHERO_TEXT2:"Our Representative will guide you, and help you to get started",
    CONTACTFORM_TEXT1:"Gain Deeper Insights into the",
    CONTACTFORM_TEXT2:"Features and Discuss Your Requirements",
    CONTACTFORM_TEXT3:"First Name",
    CONTACTFORM_TEXT4:"Enter first name",
    CONTACTFORM_TEXT5:"Last Name",
    CONTACTFORM_TEXT6:"Enter last name",
    CONTACTFORM_TEXT7:"Email",
    CONTACTFORM_TEXT8:"Enter your email address",
    CONTACTFORM_TEXT9:"Phone",
    CONTACTFORM_TEXT10:"Enter your phone number",
    CONTACTFORM_TEXT11:"Message",
    CONTACTFORM_TEXT12:"Send Message",
    ADDRESS_TEXT1:"Feel free to contact us",
    ADDRESS_TEXT2:"P - 152 , Nooraniyah BLDG,",
    ADDRESS_TEXT3:"Hor Al Anz Dubai",
    ADDRESS_TEXT4:" B Dawood Colony, Susan Road,",
    ADDRESS_TEXT5:"Madina Town, Faisalabad.",
    INTEGRATIONPAGE_TEXT1:"Integration",
    INTEGRATIONPAGE_TEXT2:"Shipra’s",
    INTEGRATIONPAGE_TEXT3:"Solution",
    INTEGRATIONPAGE_TEXT4:"Integrate Shipra with your system and automate your shipment booking. Your order information is imported and you can assign it to a partner courier company and print labels. you can connect your systems in several ways",
    INTEGRATIONPAGE_TEXT5:"Join Now Free",
    INTEGRATIONPAGE_TEXT6:"with",
    INTEGRATIONSTORE_TEXT1:"online stores",
    INTEGRATIONSTORE_TEXT2:" Integrate with your ",
    INTEGRATIONSTORE_TEXT3:"Store easily",
    INTEGRATIONSTORE_TEXT4:"Shopify",
    INTEGRATIONSTORE_TEXT5:"Shopify is a popular e-commerce platform that allows businesses to create and manage their online stores. It was founded in 2006 and has since grown to become one of the most widely used.",
    INTEGRATIONSTORE_TEXT6:"Woo Commerce",
    INTEGRATIONSTORE_TEXT7:"Shopify is a popular e-commerce platform that allows businesses to create and manage their online stores. It was founded in 2006 and has since grown to become one of the most widely used.",
    INTEGRATIONSTORE_TEXT8:"Magento",
    INTEGRATIONSTORE_TEXT9:"Shopify is a popular e-commerce platform that allows businesses to create and manage their online stores. It was founded in 2006 and has since grown to become one of the most widely used.",
    INTEGRATIONSTORE_TEXT10:"Big Commerce",
    INTEGRATIONSTORE_TEXT11:"Shopify is a popular e-commerce platform that allows businesses to create and manage their online stores. It was founded in 2006 and has since grown to become one of the most widely used.",
    FEATURE_TEXT1:"Easy to use & documented ",
    FEATURE_TEXT2:"Reliable 99.99% Uptime",
    FEATURE_TEXT3:"Tested on millions of shipments",
    FEATURE_TEXT4:"Available on all plans",
    STEPS_TEXT1:"First Step",
    STEPS_TEXT2:"Where do you start",
    STEPS_TEXT3:"your Journey",
    STEPS_TEXT4:"Setup Your Account",
    STEPS_TEXT5:"Setting up your account is the first step towards accessing the features and benefits of a service or platform.",
    STEPS_TEXT6:"Sign up Here",
    STEPS_TEXT7:"Connect Your Store",
    STEPS_TEXT8:" A feature that allows businesses to integrate their online e-commerce platforms with various marketing and sales channels.",
    STEPS_TEXT9:"Ready made Plugins",
    STEPS_TEXT10:"Integrate APIs & Push Orders",
    STEPS_TEXT11:"Integrating APIs and pushing orders allows businesses to automate their order processing and fulfillment.",
    STEPS_TEXT12:"Api Documentations",
    TESTIMONIAL_TEXT1:"Testimonials",
    TESTIMONIAL_TEXT2:"What our Happy",
    TESTIMONIAL_TEXT3:"Customers say",
    TESTIMONIAL_TEXT4:"Fantastic job done by Shipra",
    TESTIMONIAL_TEXT5:"Shipra has completely transformed our logistics operations. The multi-channel inventory management and real-time tracking have saved us so much time and effort. Our customers are happier, and our business is running smoother than ever!",
    TESTIMONIAL_TEXT6:"Amir H.",
    TESTIMONIAL_TEXT7:"E-commerce Business Owner",
    TESTIMONIAL_TEXT8:"Fantastic job done by Shipra",
    TESTIMONIAL_TEXT9:"The integration with our Shopify store was seamless, and the unified tracking from multiple carriers has been a game-changer. Shipra’s OMS/WMS system has taken the complexity out of our order fulfillment process. Highly recommend it!",
    TESTIMONIAL_TEXT10:"Sarah K.",
    TESTIMONIAL_TEXT11:"Operations Manager",
    TESTIMONIAL_TEXT12:"Fantastic job done by Shipra",
    TESTIMONIAL_TEXT13:"As a delivery company, the ability to manage COD and RTO through Shipra has significantly improved our cash flow and reduced return rates. Their driver app is also a fantastic tool for our fleet. We’ve never been more efficient!",
    TESTIMONIAL_TEXT14:"Ahmed S.",
    TESTIMONIAL_TEXT15:"Logistics Manager",
    TESTIMONIAL_TEXT16:"Shipra's automated SMS and WhatsApp updates have taken customer communication to the next level. Our clients are constantly updated on their order status, and we’ve seen a huge drop in non-delivery issues. It’s an essential tool for any e-commerce business.",
    TESTIMONIAL_TEXT17:"Fatima M.",
    TESTIMONIAL_TEXT18:"Customer Service Lead",
    TESTIMONIAL_TEXT19:"The ease of managing returns and restocking with Shipra has streamlined our entire post-delivery process. It's like having an extra team member handling everything behind the scenes. We can now focus more on scaling our business.",
    TESTIMONIAL_TEXT20:"Omar R.",
    TESTIMONIAL_TEXT21:"Fulfillment Specialist",
    TESTIMONIAL_TEXT22:"We’ve tried multiple platforms, but Shipra stands out with its powerful features and user-friendly interface. The pre-integrated carriers and accurate inventory management have been invaluable for our growing business. Shipra is a must-have for anyone in e-commerce!",
    TESTIMONIAL_TEXT23:"Zainab A.",
    TESTIMONIAL_TEXT24:"Founder & CEO",
    INTEGRATION_TEXT1:"integration",
    INTEGRATION_TEXT2:"Examples of Shipra",
    INTEGRATION_TEXT3:"Integration",
    INTEGRATION_TEXT4:"Connect  Store",
    INTEGRATION_TEXT5:"Learn how you can connect your online store with Shipra. Check out a quick example of Shopify.",
    INTEGRATION_TEXT6:"See Example",
    INTEGRATION_TEXT7:"API Integration",
    INTEGRATION_TEXT8:"Explore a simple demonstration of API integration using .NET. Check out a quick example.",
    INTEGRATION_TEXT9:"See Example",
    ANALYTICS_TEXT1:"Analytics and Report",
    ANALYTICS_TEXT2:"Access dozens of pre-built expert reports to give you deep insights",
    ANALYTICS_TEXT3:"better insights",
    ANALYTICS_TEXT4:"Know what, when &",
    ANALYTICS_TEXT5:"why with Analytics",
    ANALYTICS_TEXT6:"Keep track of your shipments",
    ANALYTICS_TEXT7:"Gain insights into your shipping data, spot recurring peaks and adapt your workflow to them.The dashboard shows you the amount of shipments over the last 30 daysand statistics per carrier or – if you have multiple stores – per shop.",
    ANALYTICS_TEXT8:"Reduce your return rates",
    ANALYTICS_TEXT9:"95% of customers that have a good return experience would buy again. Trust your own data to identify trends in return rates and improve your return process to let customers shop with you again, even after an order wasn’t up to their expectations.",
    ANALYTICS_TEXT10:"Export shipping data as CSV",
    ANALYTICS_TEXT11:"See the bigger picture and export your shipping and return data as an CSV file in just one click, to compare them with other data from your business. The numbers tell the tale!",
    ANALYTICS_TEXT12:"Monthly overview in your dashboard",
    ANALYTICS_TEXT13:"Don’t have time to investigate your shipping and return data thoroughly? Shipra have got your back! Receive an overview of how you did every month, right in your analytics dashboard",
    ANALYTICS_TEXT14:"Orders Performance",
    ANALYTICS_TEXT15:"Audit your business and analyze the delivery performance graph. Reports helps you right decision for your business as per the delivery ratio. Analyze harmful products that impact your business",
    ANALYTICS_TEXT16:"Carriers Performance",
    ANALYTICS_TEXT17:"Collect prospects per carrier that are handling your orders on daily basis. Reach out to them or switch to other carriers based upon delivery success percentage. Monitor the status of your orders",
    ANALYTICS_TEXT18:"Daily Updates",
    ANALYTICS_TEXT19:"Compare the similarities and differences between carriers services and order attempts by carriers side by side",
    ANALYTICS_TEXT20:"Reports To Gain",
    ANALYTICS_TEXT21:"Bussiness Insights",
    ANALYTICS_TEXT22:"#1 Analyze Orders",
    ANALYTICS_TEXT23:"Discover your order results by seeing reports on weekly or monthly basis shared with you on dashboard.Study your orders success and cancel reason based on delivery serviceor product for a specific period of time.Find your untapped and missing oppertunities based on your current bussiness analytics and take necessary moves to improve order success",
    ANALYTICS_TEXT24:"#2 Most Selling Product",
    ANALYTICS_TEXT25:"See your shipra analytics reports reffering to most demanding products. Discover the most important item you are selling over a specific period of time and get useful results. Check your competative product that got most of the attention and consider covering these items for selling on your site most often to generate great revenue. Study your own items and eliminate the suspecious ones to avoid order rejections. Add trending products similar to top selling products to your inventory to increase daily order orders volume ",
    ANALYTICS_TEXT26:"#3 Profit Calculations",
    ANALYTICS_TEXT27:"Combine multiple advance filters at once to get your monthly profit margins based upon your success deliveries. Compare your current month busssiness with last month bussiness profit percentage. Analytics help you to identify your bussiness growth. export your discovered data to XLSX, CSV or PDF format reports.",
    ANALYTICS_TEXT28:"#4 Profit Calculations",
    ANALYTICS_TEXT29:"Combine multiple advance filters at once to get your monthly profit margins based upon your success deliveries. Compare your current month busssiness with last month bussiness profit percentage. Analytics help you to identify your bussiness growth. export your discovered data to XLSX, CSV or PDF format reports.",
    ANALYTICS_TEXT30:"Strong Carrier Connections",
    ANALYTICS_TEXT31:"Get variety of top carriers connected with you through shipra account",
    ANALYTICS_TEXT32:"Fullfilment and Assignment",
    ANALYTICS_TEXT33:"Barcode based item inventory allows you to effectively fulfill your order and assign to carrier with a single click",
    ANALYTICS_TEXT34:"Unified Tracking",
    ANALYTICS_TEXT35:"Get all the carrier orders tracking at one place. Regular and real time tracking keep you updated with all your order status.",
    ANALYTICS_TEXT36:"Print PDF Reports",
    ANALYTICS_TEXT37:"Multi Carrier comparison, Top Products, Bussiness Analytics, Growth graphs, monthly order report and more",
    ANALYTICS_TEXT38:"Email, Schedule Reorder",
    ANALYTICS_TEXT39:"Keep track of your items reordering with complete inventory system in shipra. Allows you to add minimum quantity reminders and more.",
    ANALYTICS_TEXT40:"Benefit From the Advanced Features",
    CONFIRMATION_TEXT:"Message sent successfully",
    ERROR_TEXT:"Something went wrong",
    CONFIRMATIONEmail_TEXT:"Thanks for Subscribing",
    BLOG_TEXT:"Blogs",
    BLOG_TEXT3:"Deep insights and innovative thoughts from Shipra’s experts.",
    BLOG_TEXT1:"Our Blogs",
    BLOG_TEXT2:"See our blogs to stay up to date",
    FEATUREHERO_TEXT:"Features",
    FEATUREHERO_TEXT2:"Increase your speed of order assigning to any courier of your choice by 80%.Automate your shipping process to ship more efficiently than ever before",
    FEATUREHERO_TEXT3:"Ship your ",
    FEATUREHERO_TEXT4:"packages in few",
    FEATUREHERO_TEXT5:"Minutes",
    FEATUREHERO_TEXT6:"Start your Free Trial",
    POWERFULLFEATURE_TEXT1:"Powerful features",
    POWERFULLFEATURE_TEXT2:"Integrate with your",
    POWERFULLFEATURE_TEXT3:"Store easily",
    POWERFULLFEATURE_TEXT4:"Unified Tracking",
    POWERFULLFEATURE_TEXT5:"Accurate Inventory Management",
    POWERFULLFEATURE_TEXT6:"COD Handling",
    POWERFULLFEATURE_TEXT7:"Integrate any Delivery company",
    POWERFULLFEATURE_TEXT8:"Integrate any Delivery company",
    POWERFULLFEATURE_TEXT9:" B2B and B2C Integrations",
    POWERFULLFEATURE_TEXT10:"Shopify Plugin",
    POWERFULLFEATURE_TEXT11:"Multi Channel & Multi Store",
    POWERFULLFEATURE_TEXT12:"Woo-Commerce plugin",
    POWERFULLFEATURE_TEXT13:"SMS Integration",
    POWERFULLFEATURE_TEXT14:"Online Payments",
    POWERFULLFEATURE_TEXT15:"And lot more",
    ADVANTAGES_TEXT1:"The Shipra Advantage",
    ADVANTAGES_TEXT2:"Trusted by top companies worldwide, we offer unmatched expertise and business value. Your success story begins with us!",
    ADVANTAGES_TEXT3:"Third-Party Connectors",
    ADVANTAGES_TEXT4:"No Upfront Cost",
    ADVANTAGES_TEXT5:"Quick & Easy",
    ADVANTAGES_TEXT6:"Payment gateway",
    ADVANTAGES_TEXT7:"Future ready ",
    DEMO_TEXT:"Demo",
    DEMO_TEXT1:"EASY-TO-USE SHIPPING SOFTWARE",
    DEMO_TEXT2:"Seamlessly Automate",
    DEMO_TEXT3:"Your Shipping Processes",
    DEMO_TEXT4:"Shipra is a shipment tracking solution that helps your business manage orders, enhance customer delivery experience, and connect to 70+ carriers using our API.",
    DEMO_TEXT5:"See",
    DEMO_TEXT6:"Shipra ",
    DEMO_TEXT7:"in Action",
    DEMO_TEXT8:"Full Name",
    DEMO_TEXT9:"Business Email",
    DEMO_TEXT10:"Phone Number",
    DEMO_TEXT11:"Company",
    DEMO_TEXT12:"Get a Free Demo",
    DEMO_TEXT13:"By proceeding, you agree to provide your information to Shipra to be used in accordance with the",
    DEMO_TEXT14:"Shipra Privacy Policy",
    DEMO_TEXT15:"Enter company name",
    DEMOFEATURE_TEXT1:"INTUITIVE AND POWERFUL FEATURES",
    DEMOFEATURE_TEXT2:"Simplify Your Operations and Ship ",
    DEMOFEATURE_TEXT3:"Increase your speed of order assigning to any courier of your ",
    DEMOFEATURE_TEXT4:"Unified Shipment Tracking",
    DEMOFEATURE_TEXT5:"Order Management",
    DEMOFEATURE_TEXT6:"B2B and B2C Integrations",
    DEMOFEATURE_TEXT7:"Shipping Label Printing",
    DEMOFEATURE_TEXT8:"Inventory Management",
    DEMOFEATURE_TEXT9:"Sales Channel Integration",
    DEMOFEATURE_TEXT10:"Your Packages in Just a Few Minutes",
    DEMOFEATURE_TEXT11:"choice by 80% and ship more efficiently than ever before.",
    DEMOFEATUREd_TEXT1:"STRESS FREE MULTI-CARRIER SHIPPING",
    DEMOFEATUREd_TEXT2:"Expand Into New Markets and",
    DEMOFEATUREd_TEXT3:"Boost Management Efficiency",
    DEMOFEATUREd_TEXT4:"Centralize Order Management",
    DEMOFEATUREd_TEXT5:"Import orders from 100+ channels, marketplaces, ERPs, CRMs, or IMSs with shipping configurations.",
    DEMOFEATUREd_TEXT6:"Experience Seamless Shipping",
    DEMOFEATUREd_TEXT7:"Use integrated workflows to manage all your orders and track courier shipment and delivery information.",
    DEMOFEATUREd_TEXT8:"Optimize Label Printing",
    DEMOFEATUREd_TEXT9:"Access orders from one dashboard for single or bulk Label Printing for any delivery company with ease.",
    DEMOMANAGEMENT_TEXT1:"EFFICIENT SHIPPING MANAGEMENT",
    DEMOMANAGEMENT_TEXT2:"All-In-One Shipping",
    DEMOMANAGEMENT_TEXT3:"Automation Platform",
    DEMOMANAGEMENT_TEXT4:"Seamlessly automate your e-commerce shipping operations. Manage your inventory, including both on-shelf and out-of-transit items, connect multiple sales channels, and track shipment details.",
    CONFIRMATIONDEMO_TEXT:"Thank you for reaching out. Will call you back within 24 hours",
    NETWORK_TEXT:"Network",
    CARRIER1_TEXT:"Shipra's last-mile carrier network helps you match the right carrier to every delivery at the lowest cost, regardless of location or delivery preference. Our network enables you to quickly introduce new delivery offerings and provide consistent service no matter what.",
    TALK_TEXT:"Let's talk",
    JOINUS_TEXT:"Join our network",
    COUNTER_TEXT1:"last mile delivery service providers",
    COUNTER_TEXT2:"multimodal carriers",
    COUNTER_TEXT3:"courier, parcel and express carriers",
    COUNTER_TEXT4:"long haul carriers",
    FILTER_BYLOCATION:"Filter by location",
    FILTER_BYSERVICE:"Filter by service",
    CLEAR_FILTER:"Clear filter",
    SERACH_CARRIER:"Search carriers",
    SOCIALSELLER1_TEXT:"No More Excel Sheets",
    SOCIALSELLER2_TEXT:"Don't overwhelm your schedule with manual work! Sell and ship more with less operational work from Shipra's one dashboard",
    SOCIALSELLER3_TEXT:"Better communication with your customers",
    SOCIALSELLER4_TEXT:"Keep your customers updated with the status of their orders and provide a consistent customer experience through Shipra customer notifications system(CNS) that reinforces your brand values.",
    SOCIALSELLERHERO1_TEXT:"Sell on Many Social Media Platforms And Ship with One All-Inclusive Dashboard",
    SOCIALSELLERHERO2_TEXT:"Shipra is shipping gateway that helps you as a social seller to get instant access to 70+ shipping company, and poowerful tracking and return systems.",
    SIGNUP_TEXT1:"Create your free Shipra account now!",
    SIGNUPEMAIL_TEXT:"Email Address (Work or Personal)",
    SIGNUPPASSWORD_TEXT:"Password",
    SIGNUP_TEXT2:"By registering, you accept",
    SIGNUP_TEXT3:"Create my free account",
    SIGNUP_TEXT4:"Already have an Shipra account?",
    SIGNUP_TEXT5 :" Shipra's Terms & Conditions and Privacy Policy",
    SIGNUPSLIDER_TEXT1:"Ship easily with 20+ carriers  starting from 13 SAR.",
    SIGNUPSLIDER_TEXT2:"Ship with Shipra rates with 20+ global & local carriers, with rates from 13 SAR. Options include Aramex, SMSA, SPL, DHL, & more!",
    SIGNUPSLIDER_TEXT3:"Ship locally & internationally.",
    SIGNUPSLIDER_TEXT4:"Ship your orders locally and internationally via the #1 shipping gateway in the Arab World.",
    SIGNUPSLIDER_TEXT5:"Connect your store or create  orders manually.",
    SIGNUPSLIDER_TEXT6:"Connect your E-commerce store or enter orders manually, then print AWBs instantly with a single click!",
    PRICING_TEXT:"Pricing",
    CARRIER_KNOWLEGD_MORE_TEXT1: "AL Services API Integration and Tracking",
    CARRIER_KNOWLEGD_MORE_TEXT2: "ClickPost Multi-carrier integration platform lets you integrate with 150+ carriers including AL Services using a single API",
    CARRIER_KNOWLEGD_MORE_TEXT3: "Table Of Contents",
    CARRIER_KNOWLEGD_MORE_TEXT4: "AL Services Services",
    CARRIER_KNOWLEGD_MORE_TEXT5: "List of AL Services APIs?",
    CARRIER_KNOWLEGD_MORE_TEXT6: "About AL Services",
    CARRIER_KNOWLEGD_MORE_TEXT7: "FAQ's",
    CARRIER_KNOWLEGD_MORE_TEXT8: "How does it work?",
    CARRIER_KNOWLEGD_MORE_TEXT9: "What else can you do with ClickPost?",
    CARRIER_KNOWLEGD_MORE_TEXT10: "AL Services Services",
    CARRIER_KNOWLEGD_MORE_TEXT11: "Shipping services",
    CARRIER_KNOWLEGD_MORE_TEXT12: "Shopify is a popular e-commerce platform that allows businesses to create and manage their online stores. It was founded in 2006 and has since grown to become one of the most widely used.",
    CARRIER_KNOWLEGD_MORE_TEXT14: "Special services",
    CARRIER_KNOWLEGD_MORE_TEXT15: "Shopify is a popular e-commerce platform that allows businesses to create and manage their online stores. It was founded in 2006 and has since grown to become one of the most widely used.",
    CARRIER_KNOWLEGD_MORE_TEXT16: "Technology services",
    CARRIER_KNOWLEGD_MORE_TEXT17: "Shopify is a popular e-commerce platform that allows businesses to create and manage their online stores. It was founded in 2006 and has since grown to become one of the most widely used.",
    CARRIER_KNOWLEGD_MORE_TEXT18: "About AL Services",
    CARRIER_KNOWLEGD_MORE_TEXT19: "AL Services is a last-mile delivery service provider for jewelry brands and marketplaces. The courier company was founded with its vision to provide end-to-end fulfillment services in the Indian region at affordable costs. OTP-verified deliveries enable brands with high-value items to be assured about the security of their items.",
    CARRIER_KNOWLEGD_MORE_TEXT20: "The courier provides its services through an API-enabled platform for easier integrations. This integration allows you to establish an automated workflow for order processing. You can even enable same-day deliveries for your customer services. This service can be availed in over 8 key cities across the country.",
    CARRIER_KNOWLEGD_MORE_TEXT21: "The mid-day delivery service of AL services allows a real-time serviceability check and has an in-transit recovery policy. These shipping services have a 95 percent delivery success rate in over 2300+ pin codes. The success rate can be credited to the real-time sync with the driver systems and visibility over the entire supply chain.",
    CARRIER_KNOWLEGD_MORE_TEXT22: "FAQs about AL Services",
    CARRIER_KNOWLEGD_MORE_TEXT23: "1) What To Do If A Shipment With AL Services Is Missing?",
    CARRIER_KNOWLEGD_MORE_TEXT24: "If your shipment will AL Services is missing or delayed, you will have to file a claim with the carrier. If your shipment is insured and the carrier is to blame, you will be reimbursed for the Average Declared amount for the shipment.",
    CARRIER_KNOWLEGD_MORE_TEXT25: "2) Can A Pickup Be Scheduled On The Same Day For Any Shipment?",
    CARRIER_KNOWLEGD_MORE_TEXT26: "AL Services has a flexible pickup service that allows you to schedule a shipment as soon as you receive it. With the API platform, you can even automate this process. The shipment can even be delivered on the same day in over 8 major cities in India.",
    CARRIER_KNOWLEGD_MORE_TEXT27: "FAQs about AL Services",
    CARRIER_KNOWLEGD_MORE_TEXT28: "How does it work?",
    CARRIER_KNOWLEGD_MORE_TEXT29: "Once integrated with ClickPost, you will never have to manually integrate with any other carrier EVER AGAIN",
    CARRIER_KNOWLEGD_MORE_TEXT30: "What else can you do with ClickPost?",
    CARRIER_KNOWLEGD_MORE_TEXT31: "Description",
    CARRIER_KNOWLEGD_MORE_TEXT32: "Used to automate the order creation process in WMS and carrier systems for easier fulfillment.",
    CARRIER_KNOWLEGD_MORE_TEXT33: "Track the progress of the package in real-time",
    CARRIER_KNOWLEGD_MORE_TEXT34: "Cancel the order in transit, and ensure it returns to the origin or nearest warehouse",
    CARRIER_KNOWLEGD_MORE_TEXT35: "Predict a near-accurate delivery date for improved conversions",
    CARRIER_KNOWLEGD_MORE_TEXT36: "Trigger an automated workflow to handle failed deliveries",
    CARRIER_KNOWLEGD_MORE_TEXT37: "Enable a streamlined returns workflow for seamless returns",
    CARRIER_KNOWLEGD_MORE_TEXT38: "API",
    CARRIER_KNOWLEGD_MORE_TEXT39: "Manifestation and shipping label generation API:	",
    CARRIER_KNOWLEGD_MORE_TEXT40: "Track Shipment API:",
    CARRIER_KNOWLEGD_MORE_TEXT41: "Cancellation API:",
    CARRIER_KNOWLEGD_MORE_TEXT42: "EDD API:",
    CARRIER_KNOWLEGD_MORE_TEXT43: "NDR Action update:",
    CARRIER_KNOWLEGD_MORE_TEXT44: "Return Web-hooks:",
    KNOW_MORE_DETAILS_TEXT1: "Multi-carrier shipping software",
    KNOW_MORE_DETAILS_TEXT2: "250+ carriers integrated",
    KNOW_MORE_DETAILS_TEXT3: "Go-live with a new carrier in 1 day",
    KNOW_MORE_DETAILS_TEXT4:  "Request Demo",
    KNOW_MORE_DETAILS_TEXT5:  "Multi-Carrier Shipping.",
    KNOW_MORE_DETAILS_TEXT6:  "Integrated Tracking.",
    KNOW_MORE_DETAILS_TEXT7:  "Returns Management.",
    KNOW_MORE_DETAILS_TEXT8:  "Why should you use multiple carriers for your e-commerce business?",
    KNOW_MORE_DETAILS_TEXT9:  "Shopify",
    KNOW_MORE_DETAILS_TEXT10:  "Shopify is a popular e-commerce platform that allows businesses to create and manage their online stores. It was founded in 2006 and has since grown to become one of the most widely used.",
    KNOW_MORE_DETAILS_TEXT11:  "Woo Commerce",
    KNOW_MORE_DETAILS_TEXT12:  "Magento",
    KNOW_MORE_DETAILS_TEXT13:  "Big Commerce",
    KNOW_MORE_DETAILS_TEXT14:  "Looking for the best multi carrier shipping solution? Try ClickPost",
    KNOW_MORE_DETAILS_TEXT15:  "Book a free demo and explore how leading e-commerce companies use ClickPost Carrier Shipping Solution and growing their online business.",
    INTEGRATIONTAB_TEXT1:"E-commerce systems",
    INTEGRATIONTAB_TEXT2:"ERP and accounting systems",
    INTEGRATIONTAB_TEXT3:"Payment gateways",
    INTEGRATIONTAB_TEXT4:"Warehouse management system (WMS)",
    INTEGRATIONTAB_TEXT5:"Connect your webshop or e-commerce tool and manage orders and shipping in one system. Pick, pack and print shipping labels efficiently while keeping your customers informed during the process.",
    INTEGRATIONTAB_TEXT6:"Have your orders automatically imported to Shipmondo. Import orders/invoices from your accounting system, book freight, and print shipping labels for your orders. Got a webshop? Automate your bookkeeping of sales orders in your accounting system.",
    INTEGRATIONTAB_TEXT7:"Integrate your payment gateways and manage orders and payments in Shipmondo. Capture payments and make refunds as an incorporated part of your workflow and return flow.",
    INTEGRATIONTAB_TEXT8:"Achieve a fast and automated workflow. Book shipping labels easily for your orders from your WMS system and minimize time spent per order.",
    RETAILER1_TEXT:"Supercharge your online retail operation with shipra",
    RETAILER2_TEXT:"Managing a successful online retail store means keeping your logistics runnig smoothly. Shipra is your complete solution to automate order fulfillment, optimize inventory, amd ensure faster deliveries-all while enhancing your customer experience",
    RETAILER3_TEXT:"Integrate your online store in minutes",
    RETAILER4_TEXT:"Easily connect your store, inport orders, and cinfigure shipments with your preferred shipping rules. Shipra allows you to set delivery options, customize fulfillemnt settings, and create branded Service Level Agreements (SLA) tailored to your business needs.",
    RETAILER5_TEXT:"Tailor your shipping options to meet your business requirements",
    RETAILER6_TEXT:"Choose your preferred shipping method-whether it's using your existing partners, in-house drivers, or Shipra's network of integrated carriers",
    RETAILER7_TEXT:"Tailor your shipping options to meet your business requirements",
    RETAILER8_TEXT:"Streamline your entire e-commerce shipping process-manage shipping, tracking, returns, and analytics accross all your sales channels effortlessly. shipra helps you offer flexible delivery options, set custom shipping rules, auto-generate labels, and send real-time shipping notifications to keep your customers informed every step of the way",
    RETAILER9_TEXT:"Improve post-purchase experience",
    RETAILER10_TEXT:"With Shipra,  you can exeed customer expectaitons by sending branded, real-time shipment updates throug SMS or WhatsApp. Keep your customer informed throughout the delivery process and gather valuable feedback on their experience to further improve your service quality",
    RETAILER11_TEXT:"Save time, effort, and speed up your shipping operations",
    RETAILERBTN1_TEXT:"Book a Demo",
    RETAILERBTN2_TEXT:"Get Started",
    SHIPPAGE_TEXT1:"Deliver the shipment with less cost, less effort, and save time",
    SHIPPAGE_TEXT2:"Leverage Shipra Pre integrated carrier network,  and deliver shipment on your terms ",
    SHIPPAGE_TEXT3:"Select the optimal shipping method for your business",
    SHIPPAGE_TEXT4:"In-House Drivers",
    SHIPPAGE_TEXT5:"Manage deliveries using your own drivers for full control",
    SHIPPAGE_TEXT6:"Hybrid Approach",
    SHIPPAGE_TEXT7:"Combine your in-house fleet with external carriers to optimize delivery efficiency and reduce costs",
    SHIPPAGE_TEXT8:"Pre-Integrated",
    SHIPPAGE_TEXT9:"Combine your in-house fleet with external carriers to optimize delivery efficiency and reduce costs",
    SHIPPAGE_TEXT10:"Ship your order locally and internationally",
    SHIPPAGE_TEXT11:"Shipra simplifies both local and international shipping by handling essential customs documentation like commercial invoices and packing lists. This ensures complaints with international regulation, reducing delays and ensuring smoother deliveries. Focus on growing your business while Shipra manages the complesities of cross-border logistics",
    SHIPPAGE_TEXT12:"Augment Deliveries Networks",
    SHIPPAGE_TEXT13:"Diversify your outsourced fleet by quickly onboarding new carriers with ease and allocate order volume based on constraints such as performance, cost, and serviceabolity",
    SHIPPAGE_TEXT14:"Optimize Carriers Selection to reduce cost per delivery",
    SHIPPAGE_TEXT15:"Simplify multi-carrier deliveries by automating carrier selection. Achieve optimum carrier utilization by factoring in constraints like delivery speed, NPS, cost, serviceability, and more",
    SHIPPAGE_TEXT16:"Save time, effort, and speed up your shipping operations",
    TRACKPAGE_TEXT1:"Unified Delivery Tracking",
    TRACKPAGE_TEXT2:"Monitor and manage your orders accoss multiple shipping companies, ensuring timely and accurate deliveries from start to finish - All in One Dashboard",
    TRACKPAGE_TEXT3:"Keep your eyes on every shipment",
    TRACKPAGE_TEXT4:"Stay updated on your shipments, whether they are delivered or in process, with real-time tracking. shipra provides a quick overview or detailed insights into your shipment's status. You can also efficientlymanage returns, making decisions on how to handle them swiftly",
    TRACKPAGE_TEXT5:"Branded tracking page",
    TRACKPAGE_TEXT6:"Enhance your customer experience with Shpra's branded tracking page. Customize the page with your logo, brand colors, and messaging, keeping your brand front and center during the entire process. Customers can follow their shipments in real-time, while you have the opportunity to showcase promotions and product recommendations, turning tracking into an engagement tool.",
    TRACKPAGE_TEXT7:"Notifications via SMS, WhatsApp,  and Email",
    TRACKPAGE_TEXT8:"Keep customers informed with real-time, branded notifications through SMS, WhatsApp, and Email. Shipra automatically sends update at each stage of the delivery process, enhaceing transparency and customer satisfaction",
    TRACKPAGE_TEXT9:"Tracking insights",
    TRACKPAGE_TEXT10:"Getting real-time analysis of delivery performance and collection feedback will help you increase customer loyalty after each purchase and make decisions for lng-term business revenue growth ",
    MANAGEPAGE_TEXT1:"Easily organize and manage your whole order fulfillment process.",
    MANAGEPAGE_TEXT2:"Connect with carriers and 3PL, simplify your shipping process, manage returns, and more.",
    MANAGEPAGE_TEXT3:"Get rid of manual shipping tasks.",
    MANAGEPAGE_TEXT4:"Connect all your sales channels and shipping partners with Shipra to automatically sync orders and manage them faster, easier, and more efficiently",
    MANAGEPAGE_TEXT5:"Keep your fulfillment costs under control",
    MANAGEPAGE_TEXT6:"A unified interface for multi-channel orders significantly cuts down administrative and order processing time, costs, and effort. Bulk actions can be completed with just a few clicks, while repetitive tasks are efficiently minimized",
    MANAGEPAGE_TEXT7:"Manage your inventory with exceptional precision.",
    MANAGEPAGE_TEXT8:"Gain full visibility into your entire stock. Sync your inventory to keep it updated, minimizing human errors and ensuring you're never understocked. Leverage insightful data from the analysis of product suppliers, sale traffic, product demand, and top-performing products.",
    MANAGEPAGE_TEXT9:"Boost your team's efficiency.",
    MANAGEPAGE_TEXT10:"Enhance your fulfillment process and achieve better results with an optimized workflow. Create and customize workflows for picking and packing. Access a multi-user account with customizable permission levels. Set up customer support users for streamlined account management",
    MANAGEPAGE_TEXT11:"Redefine the way you manage your orders.",

}
export const MainTextArabic={
    INTEGRATIONS_TEXT:"تكاملات",
    CONTACTUS_TEXT:"اتصل بنا",
    ANALYTICS_TEXT:"تحليلات",
    TRACK_TEXT:"مسار",
    MANAGE_TEXT:"يدير",
    PRODUCT_TEXT:"منتج",
    RESOURCES_TEXT:"موارد",
    VIDEOS_TEXT:"فيديوهات",
    CASESTUDY_TEXT:"دراسات الحالة",
    SHIP_TEXT:"سفينة",
    JOINNOW_TEXT:"نضم الان",
    SIGNIN_TEXT:"تسجيل الدخول",
    SIGNUP_TEXT:"اشتراك",
    ARABIC_TEXT:"عربي",
    ENGLISH_TEXT:"إنجليزي",
    CARRIER_TEXT:"شركات النقل",
    LANGUAGE_TEXT:"لغة",
    HEROTEXT1_TEXT:"سلس",
    SHIPPING_TEXT:"شحن",
    HEROTEXT2_TEXT:"مع اختيارك من",
    HEROTEXT3_TEXT:"شريك",
    HEROTEXT5_TEXT:"الخدمات اللوجستية",
    LETSTALS_TEXT:"دعونا نتحدث",
    HEROTEXT4_TEXT:"قم بأتمتة عملية الشحن الخاصة بك مع تقديم تجربة تسليم من المستوى التالي لعملائك. اتصل بسهولة بأكثر من 70 شركة نقل باستخدام واجهة برمجة التطبيقات الخاصة بنا.",
    HEROBTN_TEXT :"ابدأ الآن مجانًا",
    WORKTEXT1_TEXT :"كيف تعمل",
    WORKTEXT2_TEXT :"الأسرع والأكثر فعالية",
    WORKTEXT3_TEXT :"طريقة شحن المنتجات",
    WORKTEXT4_TEXT :"أوامر الاستيراد",
    WORKTEXT5_TEXT :"أوامر الاستيراد من أكثر من 100 قناة بيع أو أسواق أو أنظمة تخطيط موارد المؤسسات أو CRMs أو IMSs مع تكوينات الشحن.",
    WORKTEXT6_TEXT :"إدارة الشحن",
    WORKTEXT7_TEXT :"استخدم سير العمل المتكامل القائم على التخصيص لإدارة طلباتك مع شركات نقل متعددة.",
    WORKTEXT8_TEXT :"طباعة الملصقات",
    WORKTEXT9_TEXT :"أوامر الوصول من Shipra Dashboard للطباعة المفردة أو المجمعة وتسليم سائق الالتقاط المرتبط بالبريد السريع.",
    WORKTEXT10_TEXT :"تتبع الطلبات",
    WORKTEXT11_TEXT :"سيتم إخطارك بمعلومات تتبع طلبك فور شحنه وتسليمه.",
    ESTABLISHEDTEXT1_TEXT:"مقرر",
    ESTABLISHEDTEXT2_TEXT:"شركات النقل الموثوقة",
    ESTABLISHEDTEXT3_TEXT:" الشحنات سنويا",
    ESTABLISHEDTEXT4_TEXT:"مستخدمو Shipra",
    FEATURETEXT1_TEXT:"سمات",
    FEATURETEXT2_TEXT:"قم بتوصيل مجموعة متنوعة من شركات النقل التي تختارها في ثوانٍ",
    FEATURETEXT3_TEXT:"اختيار ساعي خالية من الإجهاد",
    FEATURETEXT4_TEXT:"تم دمج أكثر من 20 شركة نقل مختلفة ، وقم بتعيين الشحنات إلى شركة الشحن التي تختارها. اختر البريد الذي يناسبك.",
    FEATURETEXT5_TEXT:"اختيار ساعي خالية من الإجهاد",
    FEATURETEXT6_TEXT:"تم دمج أكثر من 20 شركة نقل مختلفة ، وقم بتعيين الشحنات إلى شركة الشحن التي تختارها. اختر البريد الذي يناسبك.",
    FEATURETEXT7_TEXT:"اختيار ساعي خالية من الإجهاد",
    FEATURETEXT8_TEXT:"تم دمج أكثر من 20 شركة نقل مختلفة ، وقم بتعيين الشحنات إلى شركة الشحن التي تختارها. اختر البريد الذي يناسبك.",
    FEATURETEXT9_TEXT:"اختيار ساعي خالية من الإجهاد",
    FEATURETEXT10_TEXT:"تم دمج أكثر من 20 شركة نقل مختلفة ، وقم بتعيين الشحنات إلى شركة الشحن التي تختارها. اختر البريد الذي يناسبك.",
    SUBSCRIBETEXT1_TEXT:"النشرة الإخبارية",
    SUBSCRIBETEXT2_TEXT:"اشترك الآن للحصول على آخر التحديثات الصناعية!",
    SUBSCRIBETEXT3_TEXT:"نقدم تقارير إحصائية عن أفضل الممارسات الصناعية ونشارك أيضًا الرؤى حول أحدث مشاريع التكامل لدينا.",
    SUBSCRIBETEXT4_TEXT:"البريد الإلكتروني المستلم",
    SUBSCRIBETEXT5_TEXT:"إشترك الآن",
    ABOUTTEXT1_TEXT:"عن",
    ABOUTTEXT2_TEXT:"ابدأ Shipra عبر الإنترنت",
    ABOUTTEXT3_TEXT:"مجانا",
    ABOUTTEXT4_TEXT:"لوحة القيادة Shipra",
    ABOUTTEXT5_TEXT:"ارتقِ بمتجرك إلى المستوى التالي مع سهولة التعامل مع تتبع طلبات الشحنات والمزيد.",
    ABOUTTEXT6_TEXT:"إدارة المخزون",
    ABOUTTEXT7_TEXT:"استخدم Shipra لإدارة مخزونك ، بما في ذلك العناصر الموجودة على الرف وخارج النقل وتتبع مستويات المخزون لديك.",
    ABOUTTEXT8_TEXT:"دعم مباشر",
    ABOUTTEXT9_TEXT:"احصل على دعم الخبراء متى احتجت إليه ، على مدار الساعة طوال أيام الأسبوع. يتوفر فريق الخبراء لدينا لمساعدتك في أي مشكلة ، كبيرة كانت أم صغيرة.",
    ABOUTTEXT10_TEXT:"حجز اجتماع",
    FOOTERTEXT1_TEXT:"تقدم Shipra أحدث الحلول المدعومة بعمليات تكامل لإدارة الطلبات اليومية وتعيين شركات نقل متعددة في جميع أنحاء المنطقة",
    FOOTERTEXT2_TEXT:"شركة",
    FOOTERTEXT3_TEXT:"منتج",
    FOOTERTEXT4_TEXT:"اتصال",
    FOOTERTEXT5_TEXT:"حقوق الطبع والنشر 2024",
    FOOTERTEXT7_TEXT:"Shipra.io™ القايد العبقري لتكنولوجيا المعلومات ذ.م.م",
    FOOTERTEXT6_TEXT:"حالات الاستخدام",
    SOCIALSELLER_TEXT:"البائعين الاجتماعيين",
    ONLINERETAILER_TEXT:"تجار التجزئة على الانترنت",
    OMNICHANNELBRANDS_TEXT:"العلامات التجارية متعددة القنوات",
    LOGISTICSPL_TEXT:"الخدمات اللوجستية و3PL",
    ABOUTTEXT_TEXT:"عن",
    FEATURETEXT_TEXT:"سمات",
    CAREERTEXT_TEXT:"حياة مهنية",
    PRICINGTEXT_TEXT:"التسعير",
    CONTACTUSTEXT_TEXT:"اتصل بنا",
    PRIVACYTEXT_TEXT:"سياسة الخصوصية",
    TERMSTEXT_TEXT:"البنود و الظروف",
    SUPPORTTEXT_TEXT:"مستندات الدعم",
    BOOKDEMO_TEXT:"كتاب تجريبي",
    CONTACTHERO_TEXT1:"ابق على تواصل معنا",
    CONTACTHERO_TEXT2:"سيقوم ممثلنا بإرشادك ومساعدتك على البدء",
    CONTACTFORM_TEXT1:"احصل على رؤى أعمق حول",
    CONTACTFORM_TEXT2:" الميزات ومناقشة متطلباتك",
    CONTACTFORM_TEXT3:"الاسم الأول",
    CONTACTFORM_TEXT4:"أدخل الاسم الأول",
    CONTACTFORM_TEXT5:"اسم العائلة",
    CONTACTFORM_TEXT6:"إدخال اسم آخر",
    CONTACTFORM_TEXT7:"بريد إلكتروني",
    CONTACTFORM_TEXT8:"أدخل عنوان بريدك الالكتروني",
    CONTACTFORM_TEXT9:"هاتف",
    CONTACTFORM_TEXT10:"أدخل رقم هاتفك",
    CONTACTFORM_TEXT11:"رسالة",
    CONTACTFORM_TEXT12:"أرسل رسالة",
    ADDRESS_TEXT1:"لا تتردد في الاتصال بنا",
    ADDRESS_TEXT2:"ص - 152، بناية نورانية،",
    ADDRESS_TEXT3:"هور العنز دبي",
    ADDRESS_TEXT4:"ب داوود كولوني ، طريق سوزان ،",
    ADDRESS_TEXT5:"المدينة المنورة ، فيصل أباد.",
    INTEGRATIONPAGE_TEXT1:"اندماج",
    INTEGRATIONPAGE_TEXT2:"شيبرا",
    INTEGRATIONPAGE_TEXT3:"حل",
    INTEGRATIONPAGE_TEXT4:"قم بدمج Shipra مع نظامك وقم بأتمتة حجز الشحنة الخاصة بك. يتم استيراد معلومات طلبك ويمكنك تعيينها لشركة بريد سريع شريكة وطباعة الملصقات. يمكنك توصيل أنظمتك بعدة طرق",
    INTEGRATIONPAGE_TEXT5:"انضم الآن مجانا",
    INTEGRATIONPAGE_TEXT6 :"مع",
    INTEGRATIONSTORE_TEXT1:"محلات نشطة",
    INTEGRATIONSTORE_TEXT2:" تكامل مع ملف ",
    INTEGRATIONSTORE_TEXT3:"تخزينها بسهولة",
    INTEGRATIONSTORE_TEXT4:"Shopify",
    INTEGRATIONSTORE_TEXT5:"Shopify هي عبارة عن منصة تجارة إلكترونية شهيرة تسمح للشركات بإنشاء وإدارة متاجرها عبر الإنترنت. تأسست في عام 2006 وتطورت منذ ذلك الحين لتصبح واحدة من أكثر الشركات استخدامًا.",
    INTEGRATIONSTORE_TEXT6:"Woo Commerce",
    INTEGRATIONSTORE_TEXT7:"Shopify هي عبارة عن منصة تجارة إلكترونية شهيرة تسمح للشركات بإنشاء وإدارة متاجرها عبر الإنترنت. تأسست في عام 2006 وتطورت منذ ذلك الحين لتصبح واحدة من أكثر الشركات استخدامًا.",
    INTEGRATIONSTORE_TEXT8:"Magento",
    INTEGRATIONSTORE_TEXT9:"Shopify هي عبارة عن منصة تجارة إلكترونية شهيرة تسمح للشركات بإنشاء وإدارة متاجرها عبر الإنترنت. تأسست في عام 2006 وتطورت منذ ذلك الحين لتصبح واحدة من أكثر الشركات استخدامًا.",
    INTEGRATIONSTORE_TEXT10:"Big Commerce",
    INTEGRATIONSTORE_TEXT11:"Shopify هي عبارة عن منصة تجارة إلكترونية شهيرة تسمح للشركات بإنشاء وإدارة متاجرها عبر الإنترنت. تأسست في عام 2006 وتطورت منذ ذلك الحين لتصبح واحدة من أكثر الشركات استخدامًا.",
    FEATURE_TEXT1:"سهل الاستخدام وموثق",
    FEATURE_TEXT2:"موثوقة 99.99٪ الجهوزية",
    FEATURE_TEXT3:"تم اختباره على ملايين الشحنات",
    FEATURE_TEXT4:"متاح في جميع الخطط",
    STEPS_TEXT1:"الخطوة الأولى",
    STEPS_TEXT2:"من أين تبدأ",
    STEPS_TEXT3:"رحلتك",
    STEPS_TEXT4:"إنشأ حسابك",
    STEPS_TEXT5:"يعد إنشاء حسابك هو الخطوة الأولى نحو الوصول إلى ميزات وفوائد الخدمة أو النظام الأساسي.",
    STEPS_TEXT6:"سجل هنا",
    STEPS_TEXT7:"قم بتوصيل متجرك",
    STEPS_TEXT8:" ميزة تسمح للشركات بدمج منصات التجارة الإلكترونية عبر الإنترنت مع قنوات التسويق والمبيعات المتنوعة.",
    STEPS_TEXT9:"الإضافات الجاهزة",
    STEPS_TEXT10:"دمج واجهات برمجة التطبيقات وأوامر الدفع",
    STEPS_TEXT11:"يسمح دمج واجهات برمجة التطبيقات (API) ودفع الطلبات للشركات بأتمتة معالجة الطلبات وتنفيذها.",
    STEPS_TEXT12:"وثائق Api",
    TESTIMONIAL_TEXT1:"الشهادات - التوصيات",
    TESTIMONIAL_TEXT2:"ما لدينا سعيد",
    TESTIMONIAL_TEXT3:"يقول العملاء",
    TESTIMONIAL_TEXT4:"عمل رائع قام به Shipra",
    TESTIMONIAL_TEXT5:"لقد قامت شركة Shipra بتحويل عملياتنا اللوجستية بالكامل. لقد وفرت لنا إدارة المخزون متعدد القنوات والتتبع في الوقت الفعلي الكثير من الوقت والجهد. أصبح عملاؤنا أكثر سعادة، وأعمالنا تسير بشكل أكثر سلاسة من أي وقت مضى!",
    TESTIMONIAL_TEXT6:"أمير ح.",
    TESTIMONIAL_TEXT7:"صاحب أعمال التجارة الإلكترونية",
    TESTIMONIAL_TEXT8:"عمل رائع قام به Shipra",
    TESTIMONIAL_TEXT9:"كان التكامل مع متجر Shopify الخاص بنا سلسًا، وقد أدى التتبع الموحد من شركات النقل المتعددة إلى تغيير قواعد اللعبة. لقد نجح نظام OMS/WMS الخاص بشركة Shipra في إزالة التعقيد من عملية تلبية الطلب لدينا. أوصي به بشدة!",
    TESTIMONIAL_TEXT10:"سارة ك.",
    TESTIMONIAL_TEXT11:"مدير العمليات",
    TESTIMONIAL_TEXT12:"عمل رائع قام به Shipra",
    TESTIMONIAL_TEXT13:"باعتبارنا شركة توصيل، أدت القدرة على إدارة COD وRTO من خلال Shipra إلى تحسين التدفق النقدي لدينا بشكل كبير وتقليل معدلات العائد. يعد تطبيق برنامج التشغيل الخاص بهم أيضًا أداة رائعة لأسطولنا. لم نكن أكثر كفاءة من أي وقت مضى!",
    TESTIMONIAL_TEXT14:"أحمد س.",
    TESTIMONIAL_TEXT15:"مدير اللوجستيات",
    TESTIMONIAL_TEXT16:"لقد نقلت تحديثات الرسائل النصية القصيرة والواتساب الآلية من Shipra التواصل مع العملاء إلى المستوى التالي. يتم تحديث عملائنا باستمرار بشأن حالة طلباتهم، وقد شهدنا انخفاضًا كبيرًا في مشكلات عدم التسليم. إنها أداة أساسية لأي عمل تجاري إلكتروني.",
    TESTIMONIAL_TEXT17:"فاطمة م.",
    TESTIMONIAL_TEXT18:"قائد خدمة العملاء",
    TESTIMONIAL_TEXT19:"لقد أدت سهولة إدارة المرتجعات وإعادة التخزين مع Shipra إلى تبسيط عملية ما بعد التسليم بأكملها. إنه مثل وجود عضو إضافي في الفريق يتولى كل شيء خلف الكواليس. يمكننا الآن التركيز بشكل أكبر على توسيع نطاق أعمالنا.",
    TESTIMONIAL_TEXT20:"عمر ر.",
    TESTIMONIAL_TEXT21:"أخصائي الوفاء",
    TESTIMONIAL_TEXT22:"لقد جربنا منصات متعددة، ولكن Shipra تتميز بميزاتها القوية وواجهتها سهلة الاستخدام. لقد كانت شركات النقل المدمجة مسبقًا والإدارة الدقيقة للمخزون لا تقدر بثمن بالنسبة لأعمالنا المتنامية. Shipra أمر لا بد منه لأي شخص في التجارة الإلكترونية!",
    TESTIMONIAL_TEXT23:"زينب أ.",
    TESTIMONIAL_TEXT24:"المؤسس والرئيس التنفيذي",
    INTEGRATION_TEXT1:"اندماج",
    INTEGRATION_TEXT2:"أمثلة على شيبرا",
    INTEGRATION_TEXT3:"اندماج",
    INTEGRATION_TEXT4:"ربط المتج",
    INTEGRATION_TEXT5:"تعرف على كيفية توصيل متجرك عبر الإنترنت بـ Shipra. تحقق من مثال سريع Shopify.",
    INTEGRATION_TEXT6:"انظر المثال",
    INTEGRATION_TEXT7:"تكامل API",
    INTEGRATION_TEXT8:"استكشف عرضًا توضيحيًا بسيطًا لتكامل API باستخدام .NET. تحقق من مثال سريع.",
    INTEGRATION_TEXT9:"انظر المثال",
    ANALYTICS_TEXT1:"التحليلات والتقرير",
    ANALYTICS_TEXT2:"قم بالوصول إلى العشرات من تقارير الخبراء المعدة مسبقًا لتمنحك رؤى عميقة",
    ANALYTICS_TEXT3:"رؤى أفضل",
    ANALYTICS_TEXT4:"تعرف ماذا ومتى &",
    ANALYTICS_TEXT5:"لماذا مع Analytics",
    ANALYTICS_TEXT6:"تتبع الشحنات الخاصة بك",
    ANALYTICS_TEXT7:"احصل على رؤى حول بيانات الشحن الخاصة بك ، وحدد فترات الذروة المتكررة وقم بتكييف سير العمل الخاص بك معها. تعرض لك لوحة المعلومات كمية الشحنات على مدار الثلاثين يومًا الماضية والإحصاءات لكل شركة نقل أو - إذا كان لديك متاجر متعددة - لكل متجر.",
    ANALYTICS_TEXT8:"تقليل معدلات عودتك",
    ANALYTICS_TEXT9:"95٪ من العملاء الذين يتمتعون بتجربة إرجاع جيدة سيشترون مرة أخرى. ثق ببياناتك الخاصة لتحديد الاتجاهات في معدلات الإرجاع وتحسين عملية الإرجاع للسماح للعملاء بالتسوق معك مرة أخرى ، حتى بعد أن كان الطلب لا يرقى إلى مستوى توقعاتهم.",
    ANALYTICS_TEXT10:"تصدير بيانات الشحن كملف CSV",
    ANALYTICS_TEXT11:"شاهد الصورة الأكبر وقم بتصدير بيانات الشحن والإرجاع كملف CSV بنقرة واحدة فقط ، لمقارنتها بالبيانات الأخرى من عملك. الأرقام تحكي الحكاية!",
    ANALYTICS_TEXT12:"نظرة عامة شهرية في لوحة القيادة الخاصة بك",
    ANALYTICS_TEXT13:"ليس لديك الوقت للتحقيق في بيانات الشحن والإرجاع بدقة؟ لقد حصلت على Shipra ظهرك! احصل على نظرة عامة حول ما قمت به كل شهر ، مباشرة في لوحة معلومات التحليلات الخاصة بك",
    ANALYTICS_TEXT14:"أداء الطلبات",
    ANALYTICS_TEXT15:"قم بتدقيق عملك وتحليل الرسم البياني لأداء التسليم. تساعدك التقارير على القرار الصحيح لعملك وفقًا لنسبة التسليم. حلل المنتجات الضارة التي تؤثر على عملك",
    ANALYTICS_TEXT16:"أداء الناقلات",
    ANALYTICS_TEXT17:"اجمع العملاء المحتملين لكل شركة نقل تتعامل مع طلباتك على أساس يومي. تواصل معهم أو انتقل إلى شركات النقل الأخرى بناءً على نسبة نجاح التسليم. راقب حالة طلباتك",
    ANALYTICS_TEXT18:"التحديثات اليومية",
    ANALYTICS_TEXT19:"قارن أوجه التشابه والاختلاف بين خدمات شركات النقل ومحاولات الطلب من جانب شركات النقل جنبًا إلى جنب",
    ANALYTICS_TEXT20:"تقارير لكسب",
    ANALYTICS_TEXT21:"رؤى الأعمال",
    ANALYTICS_TEXT22:"# 1 تحليل الطلبات",
    ANALYTICS_TEXT23:"اكتشف نتائج طلبك من خلال الاطلاع على تقارير أسبوعية أو شهرية يتم مشاركتها معك على لوحة القيادة. ادرس نجاح طلباتك وإلغاء السبب بناءً على خدمة التوصيل أو المنتج لفترة زمنية محددة ، اعثر على فرصك غير المستغلة والمفقودة بناءً على تحليلات الأعمال الحالية و اتخاذ الخطوات اللازمة لتحسين نجاح النظام",
    ANALYTICS_TEXT24:"# 2 المنتج الأكثر مبيعًا",
    ANALYTICS_TEXT25:"اطلع على تقارير تحليلات Shipra الخاصة بك التي تشير إلى المنتجات الأكثر تطلبًا. اكتشف أهم عنصر تبيعه خلال فترة زمنية محددة واحصل على نتائج مفيدة. تحقق من منتجك التنافسي الذي حظي بأكبر قدر من الاهتمام وفكر في تغطية هذه العناصر للبيع على موقعك في أغلب الأحيان لتحقيق إيرادات كبيرة. ادرس العناصر الخاصة بك وتخلص من العناصر المشبوهة لتجنب رفض الطلبات. أضف منتجات رائجة مشابهة للمنتجات الأكثر مبيعًا إلى مخزونك لزيادة حجم أوامر الطلبات اليومية ",
    ANALYTICS_TEXT26:"# 3 حسابات الربح",
    ANALYTICS_TEXT27:"اجمع بين عدة مرشحات مسبقة في وقت واحد لتحصل على هوامش ربحك الشهرية بناءً على نجاحاتك. قارن عملك في الشهر الحالي مع نسبة أرباح الأعمال في الشهر الماضي. تساعدك التحليلات على تحديد نمو عملك. تصدير بياناتك المكتشفة إلى تقارير بتنسيق XLSX أو CSV أو PDF.    ",
    ANALYTICS_TEXT28:"# 4 حسابات الربح",
    ANALYTICS_TEXT29:"اجمع بين عدة مرشحات مسبقة في وقت واحد لتحصل على هوامش ربحك الشهرية بناءً على نجاحاتك. قارن عملك في الشهر الحالي مع نسبة أرباح الأعمال في الشهر الماضي. تساعدك التحليلات على تحديد نمو عملك. تصدير بياناتك المكتشفة إلى تقارير بتنسيق XLSX أو CSV أو PDF.",
    ANALYTICS_TEXT30:"اتصالات الناقل القوية",
    ANALYTICS_TEXT31:"احصل على مجموعة متنوعة من أفضل شركات النقل المرتبطة بك من خلال حساب Shipra",
    ANALYTICS_TEXT32:"Fullfilment والتنازل",
    ANALYTICS_TEXT33:"يتيح لك مخزون العناصر المستند إلى الرمز الشريطي تنفيذ طلبك بشكل فعال والتعيين إلى شركة النقل بنقرة واحدة",
    ANALYTICS_TEXT34:"تتبع موحد",
    ANALYTICS_TEXT35:"احصل على تتبع جميع طلبات الناقل في مكان واحد. تتبع منتظم وحقيقي يبقيك على اطلاع دائم بكل حالة طلبك.",
    ANALYTICS_TEXT36:"طباعة تقارير PDF",
    ANALYTICS_TEXT37:"مقارنة شركات النقل المتعددة ، أفضل المنتجات ، تحليلات الأعمال ، الرسوم البيانية للنمو ، تقرير الطلبات الشهري والمزيد",
    ANALYTICS_TEXT38:"البريد الإلكتروني ، جدولة إعادة الترتيب",
    ANALYTICS_TEXT39:"تتبع إعادة ترتيب العناصر الخاصة بك مع نظام الجرد الكامل في Shipra. يسمح لك بإضافة تذكيرات بالحد الأدنى من الكمية والمزيد.",
    ANALYTICS_TEXT40:"استفد من الميزات المتقدمة",
    CONFIRMATION_TEXT:"تم إرسال الرسالة بنجاح",
    ERROR_TEXT:"هناك خطأ ما",
    CONFIRMATIONEmail_TEXT:" شكرا على الإشتراك",
    BLOG_TEXT:"المدونات",
    BLOG_TEXT1:"مدوناتنا",
    BLOG_TEXT2:"انظر إلى مدوناتنا لتبقى على اطلاع",
    BLOG_TEXT3:"رؤى عميقة وأفكار مبتكرة من خبراء Shipra.",
    FEATUREHERO_TEXT:"سمات",
    FEATUREHERO_TEXT2:"قم بزيادة سرعة تسليم الطلبات إلى أي شركة شحن من اختيارك بنسبة 80٪. أتمتة عملية الشحن الخاصة بك للشحن بكفاءة أكبر من أي وقت مضى",
    FEATUREHERO_TEXT3:"اشحن ",
    FEATUREHERO_TEXT4:"حزم في قليل",
    FEATUREHERO_TEXT5:"دقائق",
    FEATUREHERO_TEXT6:"ابدأ تجربتك المجانية",
    POWERFULLFEATURE_TEXT1:"ميزات قوية",
    POWERFULLFEATURE_TEXT2:"تكامل مع ملف",
    POWERFULLFEATURE_TEXT3:"تخزينها بسهولة",
    POWERFULLFEATURE_TEXT4:"تتبع موحد",
    POWERFULLFEATURE_TEXT5:"إدارة دقيقة للمخزون",
    POWERFULLFEATURE_TEXT6:"التعامل مع COD",
    POWERFULLFEATURE_TEXT7:"دمج أي شركة توصيل",
    POWERFULLFEATURE_TEXT8:"دمج أي شركة توصيل",
    POWERFULLFEATURE_TEXT9:" تكامل B2B و B2C",
    POWERFULLFEATURE_TEXT10:"Shopify البرنامج المساعد",
    POWERFULLFEATURE_TEXT11:"متعدد القنوات ومتجر متعدد",
    POWERFULLFEATURE_TEXT12:"البرنامج المساعد Woo-Commerce",
    POWERFULLFEATURE_TEXT13:"تكامل الرسائل القصيرة",
    POWERFULLFEATURE_TEXT14:"المدفوعات عبر الإنترنت",
    POWERFULLFEATURE_TEXT15:"وغير ذلك الكثير",
    ADVANTAGES_TEXT1:"ميزة Shipra",
    ADVANTAGES_TEXT2:"موثوق بها من قبل أفضل الشركات في جميع أنحاء العالم ، نحن نقدم خبرة لا مثيل لها وقيمة تجارية. قصة نجاحك تبدأ معنا!",
    ADVANTAGES_TEXT3:"موصلات الطرف الثالث",
    ADVANTAGES_TEXT4:"بدون تكلفة مقدمة",
    ADVANTAGES_TEXT5:"سريع وسهل",
    ADVANTAGES_TEXT6:"بوابة الدفع",
    ADVANTAGES_TEXT7:"المستقبل جاهز",
    DEMO_TEXT:"تجريبي",
    DEMO_TEXT1:"برنامج شحن سهل الاستخدام",
    DEMO_TEXT2:"أتمتة بسلاسة",
    DEMO_TEXT3:"عمليات الشحن الخاصة بك",
    DEMO_TEXT4:"Shipra هو حل لتتبع الشحنات يساعد شركتك على إدارة الطلبات، وتعزيز تجربة تسليم العملاء، والاتصال بأكثر من 70 شركة نقل باستخدام واجهة برمجة التطبيقات (API) الخاصة بنا.",
    DEMO_TEXT5:"يرى",
    DEMO_TEXT6:"شيبرا ",
    DEMO_TEXT7:"في العمل",
    DEMO_TEXT8:"الاسم الكامل",
    DEMO_TEXT9:"بريد العمل",
    DEMO_TEXT10:"رقم التليفون",
    DEMO_TEXT11:"شركة",
    DEMO_TEXT12:"احصل على عرض تجريبي مجاني",
    DEMO_TEXT13:"من خلال المتابعة، فإنك توافق على تقديم معلوماتك إلى Shipra لاستخدامها وفقًا لـ",
    DEMO_TEXT14:"سياسة خصوصية شيبرا",
    DEMO_TEXT15:"أدخل اسم الشركة",
    DEMOFEATURE_TEXT1:"ميزات بديهية وقوية",
    DEMOFEATURE_TEXT2:"قم بتبسيط عملياتك واشحن ",
    DEMOFEATURE_TEXT3:"زيادة سرعة تعيين الطلب إلى أي ساعي خاص بك ",
    DEMOFEATURE_TEXT4:"تتبع الشحنة الموحدة",
    DEMOFEATURE_TEXT5:"إدارة الطلبات",
    DEMOFEATURE_TEXT6:"التكامل بين B2B وB2C",
    DEMOFEATURE_TEXT7:"طباعة ملصق الشحن",
    DEMOFEATURE_TEXT8:"ادارة المخزون",
    DEMOFEATURE_TEXT9:"تكامل قنوات المبيعات",
    DEMOFEATURE_TEXT10:"الطرود الخاصة بك في دقائق معدودة",
    DEMOFEATURE_TEXT11:"الاختيار بنسبة 80% والشحن بكفاءة أكبر من أي وقت مضى.",
    DEMOFEATUREd_TEXT1:"شحن مجاني متعدد الناقلات",
    DEMOFEATUREd_TEXT2:"التوسع في أسواق جديدة و",
    DEMOFEATUREd_TEXT3:"تعزيز كفاءة الإدارة",
    DEMOFEATUREd_TEXT4:"مركزية إدارة الطلبات",
    DEMOFEATUREd_TEXT5:"قم باستيراد الطلبات من أكثر من 100 قناة، أو سوق، أو ERPs، أو CRMs، أو IMSs مع تكوينات الشحن.",
    DEMOFEATUREd_TEXT6:"تجربة الشحن السلس",
    DEMOFEATUREd_TEXT7:"استخدم سير العمل المتكامل لإدارة جميع طلباتك وتتبع معلومات الشحن والتسليم الخاصة بالبريد السريع.",
    DEMOFEATUREd_TEXT8:"تحسين طباعة الملصقات",
    DEMOFEATUREd_TEXT9:"قم بالوصول إلى الطلبات من لوحة تحكم واحدة لطباعة الملصقات الفردية أو المجمعة لأي شركة توصيل بسهولة.",
    DEMOMANAGEMENT_TEXT1:"إدارة الشحن الفعالة",
    DEMOMANAGEMENT_TEXT2:"الكل في واحد الشحن",
    DEMOMANAGEMENT_TEXT3:"منصة الأتمتة",
    DEMOMANAGEMENT_TEXT4:"أتمتة عمليات الشحن الخاصة بالتجارة الإلكترونية بسهولة. قم بإدارة مخزونك، بما في ذلك العناصر الموجودة على الرفوف والخارجة عن النقل، وربط قنوات مبيعات متعددة، وتتبع تفاصيل الشحن.",
    CONFIRMATIONDEMO_TEXT:"شكرا لك على التواصل. سوف نتصل بك مرة أخرى خلال 24 ساعة",
    NETWORK_TEXT:"شبكة",
    CARRIER1_TEXT:"تساعدك شبكة شركات النقل الخاصة بـ Shipra في الميل الأخير على مطابقة شركة النقل المناسبة لكل عملية تسليم بأقل تكلفة، بغض النظر عن الموقع أو تفضيلات التسليم. تمكنك شبكتنا من تقديم عروض توصيل جديدة بسرعة وتقديم خدمة متسقة مهما كانت الظروف.",
    TALK_TEXT:"دعونا نتحدث",
    JOINUS_TEXT:"انضم إلى شبكتنا",
    COUNTER_TEXT1:"مقدمي خدمات توصيل الميل الأخير",
    COUNTER_TEXT2:"ناقلات متعددة الوسائط",
    COUNTER_TEXT3:"شركات البريد السريع والطرود والنقل السريع",
    COUNTER_TEXT4:"ناقلات لمسافات طويلة",
    FILTER_BYLOCATION:"التصفية حسب الموقع",
    FILTER_BYSERVICE:"التصفية حسب الخدمة",
    CLEAR_FILTER:"مرشح واضح",
    SERACH_CARRIER:"ناقلات البحث",
    SOCIALSELLER1_TEXT:"لا مزيد من أوراق Excel",
    SOCIALSELLER2_TEXT:"لا تطغى على جدول أعمالك بالعمل اليدوي! قم ببيع وشحن المزيد مع عمل تشغيلي أقل من خلال لوحة تحكم Shipra الواحدة",
    SOCIALSELLER3_TEXT:"تواصل أفضل مع عملائك",
    SOCIALSELLER4_TEXT:"أبقِ عملائك مطلعين على حالة طلباتهم ووفّر لهم تجربة متسقة للعملاء من خلال نظام إشعارات عملاء Shipra (CNS) الذي يعزز قيم علامتك التجارية.",
    SOCIALSELLERHERO1_TEXT:"يمكنك البيع على العديد من منصات التواصل الاجتماعي والشحن باستخدام لوحة تحكم واحدة شاملة",
    SOCIALSELLERHERO2_TEXT:"Shipra هي بوابة شحن تساعدك كبائع اجتماعي على الوصول الفوري إلى أكثر من 70 شركة شحن وأنظمة تتبع وإرجاع قوية.",
    SIGNUP_TEXT1:"قم بإنشاء حساب Shipra المجاني الخاص بك الآن!",
    SIGNUPEMAIL_TEXT:"عنوان البريد الإلكتروني (العمل أو الشخصي)",
    SIGNUPPASSWORD_TEXT:"كلمة المرور",
    SIGNUP_TEXT2:"بالتسجيل فإنك تقبل",
    SIGNUP_TEXT3:"إنشاء حسابي المجاني",
    SIGNUP_TEXT4:"هل لديك حساب Shipra بالفعل؟",
    SIGNUP_TEXT5 :" شروط وأحكام وسياسة الخصوصية الخاصة بـ Shipra",
    SIGNUPSLIDER_TEXT1:"اشحن بسهولة مع أكثر من 20 شركة نقل ابتداءً من 13 ريال سعودي.",
    SIGNUPSLIDER_TEXT2:"اشحن مع أسعار Shipra مع أكثر من 20 شركة طيران عالمية ومحلية، بأسعار تبدأ من 13 ريال سعودي. تشمل الخيارات Aramex وSMSA وSPL وDHL والمزيد!",
    SIGNUPSLIDER_TEXT3:"الشحن محليا ودوليا.",
    SIGNUPSLIDER_TEXT4:"اشحن طلباتك محلياً ودولياً عبر بوابة الشحن رقم 1 في العالم العربي.",
    SIGNUPSLIDER_TEXT5:"قم بتوصيل متجرك أو قم بإنشاء الطلبات يدويًا.",
    SIGNUPSLIDER_TEXT6:"قم بتوصيل متجر التجارة الإلكترونية الخاص بك أو قم بإدخال الطلبات يدويًا، ثم قم بطباعة AWBs على الفور بنقرة واحدة!",
    PRICING_TEXT:"التسعير",
    CARRIER_KNOWLEGD_MORE_TEXT1: "تكامل وتتبع واجهة برمجة التطبيقات (API) لخدمات AL",
    CARRIER_KNOWLEGD_MORE_TEXT2: "تتيح لك منصة التكامل متعددة الشركات ClickPost التكامل مع أكثر من 150 شركة نقل بما في ذلك خدمات AL باستخدام واجهة برمجة تطبيقات واحدة.",
    CARRIER_KNOWLEGD_MORE_TEXT3: "فهرس المحتويات",
    CARRIER_KNOWLEGD_MORE_TEXT4: "خدمات AL",
    CARRIER_KNOWLEGD_MORE_TEXT5: "قائمة بواجهات برمجة التطبيقات (APIs) لخدمات AL",
    CARRIER_KNOWLEGD_MORE_TEXT6: "عن خدمات AL",
    CARRIER_KNOWLEGD_MORE_TEXT7: "الأسئلة الشائعة",
    CARRIER_KNOWLEGD_MORE_TEXT8: "كيف يعمل؟",
    CARRIER_KNOWLEGD_MORE_TEXT9: "ماذا يمكنك أيضًا أن تفعل باستخدام ClickPost؟",
    CARRIER_KNOWLEGD_MORE_TEXT10: "خدمات AL",
    CARRIER_KNOWLEGD_MORE_TEXT11: "خدمات الشحن",
    CARRIER_KNOWLEGD_MORE_TEXT12: "شوبيفاي هي منصة تجارة إلكترونية شهيرة تتيح للشركات إنشاء وإدارة متاجرها عبر الإنترنت. تم تأسيسها في عام 2006 ومنذ ذلك الحين نمت لتصبح واحدة من الأكثر استخدامًا.",
    CARRIER_KNOWLEGD_MORE_TEXT14: "خدمات خاصة",
    CARRIER_KNOWLEGD_MORE_TEXT15: "شوبيفاي هي منصة تجارة إلكترونية شهيرة تتيح للشركات إنشاء وإدارة متاجرها عبر الإنترنت. تم تأسيسها في عام 2006 ومنذ ذلك الحين نمت لتصبح واحدة من الأكثر استخدامًا.",
    CARRIER_KNOWLEGD_MORE_TEXT16: "خدمات التكنولوجيا",
    CARRIER_KNOWLEGD_MORE_TEXT17: "شوبيفاي هي منصة تجارة إلكترونية شهيرة تتيح للشركات إنشاء وإدارة متاجرها عبر الإنترنت. تم تأسيسها في عام 2006 ومنذ ذلك الحين نمت لتصبح واحدة من الأكثر استخدامًا.",
    CARRIER_KNOWLEGD_MORE_TEXT18: "عن خدمات AL",
    CARRIER_KNOWLEGD_MORE_TEXT19: "شركة AL Services هي مزود لخدمات التوصيل للميل الأخير لعلامات المجوهرات والأسواق. تم تأسيس شركة الشحن برؤية تقديم خدمات التنفيذ الشاملة في المنطقة الهندية بتكلفة معقولة. تُمكّن عمليات التسليم التي يتم التحقق منها برمز التحقق (OTP) العلامات التجارية ذات العناصر عالية القيمة من التأكد من أمان عناصرها.",
    CARRIER_KNOWLEGD_MORE_TEXT20: "توفر شركة الشحن خدماتها من خلال منصة تدعم تكامل واجهة برمجة التطبيقات (API) لسهولة التكامل. يتيح لك هذا التكامل إنشاء سير عمل مؤتمت لمعالجة الطلبات. يمكنك حتى تفعيل خدمات التسليم في نفس اليوم لعملائك. هذه الخدمة متاحة في أكثر من 8 مدن رئيسية في جميع أنحاء البلاد.",
    CARRIER_KNOWLEGD_MORE_TEXT21: "تتيح خدمة التوصيل منتصف اليوم من AL Services التحقق من إمكانية تقديم الخدمة في الوقت الفعلي، وتتمتع بسياسة استرداد أثناء النقل. تتمتع هذه خدمات الشحن بمعدل نجاح تسليم يصل إلى 95 بالمائة في أكثر من 2300 رمز بريدي. يمكن إرجاع معدل النجاح هذا إلى التزامن في الوقت الفعلي مع أنظمة السائقين والرؤية الكاملة على سلسلة التوريد بأكملها.",
    CARRIER_KNOWLEGD_MORE_TEXT22: "الأسئلة الشائعة حول AL Services",
    CARRIER_KNOWLEGD_MORE_TEXT23: "1) ماذا أفعل إذا كانت هناك شحنة مفقودة مع AL Services؟",
    CARRIER_KNOWLEGD_MORE_TEXT24: "إذا كانت شحنتك مع AL Services مفقودة أو متأخرة، يجب عليك تقديم مطالبة إلى شركة الشحن. إذا كانت شحنتك مؤمنة وكان الخطأ من شركة الشحن، فستتم تعويضك عن المبلغ المصرح به المتوسط للشحنة.",
    CARRIER_KNOWLEGD_MORE_TEXT25: "2) هل يمكن جدولة عملية استلام في نفس اليوم لأي شحنة؟",
    CARRIER_KNOWLEGD_MORE_TEXT26: "تمتلك AL Services خدمة استلام مرنة تتيح لك جدولة شحنة بمجرد استلامها. باستخدام منصة API، يمكنك حتى أتمتة هذه العملية. يمكن تسليم الشحنة في نفس اليوم أيضًا في أكثر من 8 مدن رئيسية في الهند.",
    CARRIER_KNOWLEGD_MORE_TEXT27: "الأسئلة الشائعة حول AL Services",
    CARRIER_KNOWLEGD_MORE_TEXT28: "كيف يعمل؟",
    CARRIER_KNOWLEGD_MORE_TEXT29: "بمجرد التكامل مع ClickPost، لن تحتاج أبدًا إلى التكامل يدويًا مع أي شركة شحن أخرى مرة أخرى.",
    CARRIER_KNOWLEGD_MORE_TEXT30: "ماذا يمكنك أيضًا أن تفعل باستخدام ClickPost؟",
    CARRIER_KNOWLEGD_MORE_TEXT31: "الوصف",
    CARRIER_KNOWLEGD_MORE_TEXT32: "يستخدم لأتمتة عملية إنشاء الطلبات في أنظمة إدارة المستودعات (WMS) وأنظمة شركات الشحن لتسهيل عملية التنفيذ.",
    CARRIER_KNOWLEGD_MORE_TEXT33: "تتبع تقدم الطرد في الوقت الفعلي",
    CARRIER_KNOWLEGD_MORE_TEXT34: "إلغاء الطلب أثناء النقل، وضمان إعادته إلى نقطة المنشأ أو أقرب مستودع.",
    CARRIER_KNOWLEGD_MORE_TEXT35: "توقع تاريخ تسليم قريب بدقة لتحسين التحويلات",
    CARRIER_KNOWLEGD_MORE_TEXT36: "تفعيل سير عمل مؤتمت للتعامل مع عمليات التسليم الفاشلة",
    CARRIER_KNOWLEGD_MORE_TEXT37: "تفعيل سير عمل مبسط لعمليات الإرجاع لضمان سلاسة عملية الإرجاع",
    CARRIER_KNOWLEGD_MORE_TEXT38: "واجهة برمجة التطبيقات",
    CARRIER_KNOWLEGD_MORE_TEXT39: "واجهة برمجة التطبيقات لتوليد بيانات الشحن وتسميات الشحن	",
    CARRIER_KNOWLEGD_MORE_TEXT40: "واجهة برمجة التطبيقات لتتبع الشحنات",
    CARRIER_KNOWLEGD_MORE_TEXT41: "واجهة برمجة التطبيقات للإلغاء",
    CARRIER_KNOWLEGD_MORE_TEXT42: "واجهة برمجة التطبيقات لتاريخ التسليم المقدر",
    CARRIER_KNOWLEGD_MORE_TEXT43: "تحديث إجراء NDR",
    CARRIER_KNOWLEGD_MORE_TEXT44: "ويب هوك للإرجاع",
    KNOW_MORE_DETAILS_TEXT1: "برنامج الشحن متعدد الشركات الناقلة",
    KNOW_MORE_DETAILS_TEXT2: "تكامل مع أكثر من 250 شركة شحن",
    KNOW_MORE_DETAILS_TEXT3: "التشغيل الفعلي مع شركة شحن جديدة في يوم واحد",
    KNOW_MORE_DETAILS_TEXT4:  "طلب عرض توضيحي",
    KNOW_MORE_DETAILS_TEXT5:  "الشحن عبر شركات متعددة",
    KNOW_MORE_DETAILS_TEXT6:  "تتبع مدمج",
    KNOW_MORE_DETAILS_TEXT7:  "إدارة الإرجاعات",
    KNOW_MORE_DETAILS_TEXT8:  "لماذا يجب عليك استخدام شركات شحن متعددة في عملك الإلكتروني؟",
    KNOW_MORE_DETAILS_TEXT9:  "شوبيفاي",
    KNOW_MORE_DETAILS_TEXT10:  "شوبيفاي هي منصة تجارة إلكترونية شهيرة تتيح للشركات إنشاء وإدارة متاجرها عبر الإنترنت. تم تأسيسها في عام 2006 ومنذ ذلك الحين نمت لتصبح واحدة من الأكثر استخدامًا.",
    KNOW_MORE_DETAILS_TEXT11:  "ووكوميرس",
    KNOW_MORE_DETAILS_TEXT12:  "ماجنتو",
    KNOW_MORE_DETAILS_TEXT13:  "بيج كومرس",
    KNOW_MORE_DETAILS_TEXT14:  "هل تبحث عن أفضل حل لشحن متعدد الشركات؟ جرب ClickPost",
    KNOW_MORE_DETAILS_TEXT15:  "احجز عرضًا توضيحيًا مجانيًا واستكشف كيف تستخدم الشركات الرائدة في التجارة الإلكترونية حل شحن الشركات من ClickPost وتنمو أعمالها عبر الإنترنت.",
    INTEGRATIONTAB_TEXT1:"أنظمة التجارة الإلكترونية",
    INTEGRATIONTAB_TEXT2:"ERP والأنظمة المحاسبية",
    INTEGRATIONTAB_TEXT3:"بوابات الدفع",
    INTEGRATIONTAB_TEXT4:"نظام إدارة المستودعات (WMS)",
    INTEGRATIONTAB_TEXT5:"قم بتوصيل متجر الويب الخاص بك أو أداة التجارة الإلكترونية وإدارة الطلبات والشحن في نظام واحد. قم باختيار ملصقات الشحن وتعبئتها وطباعتها بكفاءة مع إبقاء عملائك على اطلاع أثناء العملية.",
    INTEGRATIONTAB_TEXT6:"قم باستيراد طلباتك تلقائيًا إلى Shipmondo. استيراد الطلبات/الفواتير من نظام المحاسبة الخاص بك، وحجز الشحن، وطباعة ملصقات الشحن لطلباتك. حصلت على متجر على شبكة الإنترنت؟ أتمتة مسك الدفاتر لأوامر المبيعات في نظامك المحاسبي.",
    INTEGRATIONTAB_TEXT7:"دمج بوابات الدفع الخاصة بك وإدارة الطلبات والمدفوعات في Shipmondo. احصل على المدفوعات وقم باسترداد المبالغ كجزء مدمج من سير عملك وتدفق الإرجاع.",
    INTEGRATIONTAB_TEXT8:"تحقيق سير عمل سريع وآلي. قم بحجز ملصقات الشحن بسهولة لطلباتك من نظام WMS الخاص بك وتقليل الوقت المستغرق لكل طلب.",
    RETAILER1_TEXT:"عزز عمليات البيع بالتجزئة عبر الإنترنت مع Shipra",
    RETAILER2_TEXT:"إن إدارة متجر بيع بالتجزئة ناجح عبر الإنترنت تعني الحفاظ على سير الخدمات اللوجستية الخاصة بك بسلاسة. Shipra هو الحل الكامل لأتمتة تنفيذ الطلبات، وتحسين المخزون، وضمان عمليات تسليم أسرع - كل ذلك مع تعزيز تجربة العملاء الخاصة بك",
    RETAILER3_TEXT:"ادمج متجرك الإلكتروني في دقائق",
    RETAILER4_TEXT:"قم بتوصيل متجرك بسهولة وإدخال الطلبات وتنسيق الشحنات باستخدام قواعد الشحن المفضلة لديك. تسمح لك Shipra بتعيين خيارات التسليم، وتخصيص إعدادات التنفيذ، وإنشاء اتفاقيات مستوى الخدمة (SLA) ذات العلامات التجارية المصممة خصيصًا لتلبية احتياجات عملك.",
    RETAILER5_TEXT:"قم بتخصيص خيارات الشحن الخاصة بك لتلبية متطلبات عملك",
    RETAILER6_TEXT:"اختر طريقة الشحن المفضلة لديك - سواء كانت تستخدم شركائك الحاليين، أو برامج التشغيل الداخلية، أو شبكة شركات النقل المتكاملة التابعة لشركة Shipra",
    RETAILER7_TEXT:"قم بتخصيص خيارات الشحن الخاصة بك لتلبية متطلبات عملك",
    RETAILER8_TEXT:"قم بتبسيط عملية شحن التجارة الإلكترونية بأكملها - إدارة الشحن والتتبع والمرتجعات والتحليلات عبر جميع قنوات المبيعات الخاصة بك دون عناء. تساعدك shipra على تقديم خيارات توصيل مرنة، وتعيين قواعد شحن مخصصة، وإنشاء ملصقات تلقائيًا، وإرسال إشعارات الشحن في الوقت الفعلي لإبقاء عملائك على اطلاع بكل خطوة على الطريق",
    RETAILER9_TEXT:"تحسين تجربة ما بعد الشراء",
    RETAILER10_TEXT:"مع Shipra، يمكنك تحقيق توقعات العملاء عن طريق إرسال تحديثات الشحن الخاصة بالعلامة التجارية في الوقت الفعلي عبر الرسائل النصية القصيرة أو WhatsApp. أبقِ عميلك على اطلاع طوال عملية التسليم واجمع تعليقات قيمة حول تجربته لتحسين جودة الخدمة بشكل أكبر",
    RETAILER11_TEXT:"توفير الوقت والجهد وتسريع عمليات الشحن الخاصة بك",
    RETAILERBTN1_TEXT:"احجز عرضًا توضيحيًا",
    RETAILERBTN2_TEXT:"ابدأ",
    SHIPPAGE_TEXT1:"تسليم الشحنة بتكلفة أقل وجهد أقل وتوفيرا للوقت",
    SHIPPAGE_TEXT2:"استفد من شبكة الناقل المتكاملة Shipra مسبقًا وقم بتسليم الشحنة وفقًا لشروطك ",
    SHIPPAGE_TEXT3:"حدد طريقة الشحن الأمثل لعملك",
    SHIPPAGE_TEXT4:"السائقين الداخليين",
    SHIPPAGE_TEXT5:"إدارة عمليات التسليم باستخدام برامج التشغيل الخاصة بك للتحكم الكامل",
    SHIPPAGE_TEXT6:"النهج الهجين",
    SHIPPAGE_TEXT7:"ادمج أسطولك الداخلي مع شركات النقل الخارجية لتحسين كفاءة التسليم وخفض التكاليف",
    SHIPPAGE_TEXT8:"متكاملة مسبقًا",
    SHIPPAGE_TEXT9:"ادمج أسطولك الداخلي مع شركات النقل الخارجية لتحسين كفاءة التسليم وخفض التكاليف",
    SHIPPAGE_TEXT10:"شحن طلبك محليا ودوليا",
    SHIPPAGE_TEXT11:"تعمل Shipra على تبسيط عملية الشحن المحلية والدولية من خلال التعامل مع الوثائق الجمركية الأساسية مثل الفواتير التجارية وقوائم التعبئة. وهذا يضمن تقديم الشكاوى مع اللوائح الدولية، مما يقلل من التأخير ويضمن عمليات تسليم أكثر سلاسة. ركز على تنمية أعمالك بينما تقوم شركة Shipra بإدارة تعقيدات الخدمات اللوجستية عبر الحدود",
    SHIPPAGE_TEXT12:"زيادة شبكات التسليم",
    SHIPPAGE_TEXT13:"قم بتنويع أسطول الاستعانة بمصادر خارجية الخاص بك عن طريق تأهيل شركات النقل الجديدة بسرعة بسهولة وتخصيص حجم الطلب بناءً على قيود مثل الأداء والتكلفة وإمكانية الخدمة",
    SHIPPAGE_TEXT14:"تحسين اختيار شركات النقل لتقليل تكلفة التسليم",
    SHIPPAGE_TEXT15:"تبسيط عمليات التسليم عبر شركات النقل المتعددة عن طريق أتمتة اختيار شركات النقل. يمكنك تحقيق الاستخدام الأمثل لشركة النقل من خلال مراعاة القيود مثل سرعة التسليم وNPS والتكلفة وإمكانية الخدمة والمزيد",
    SHIPPAGE_TEXT16:"توفير الوقت والجهد وتسريع عمليات الشحن الخاصة بك",
    TRACKPAGE_TEXT1:"تتبع التسليم الموحد",
    TRACKPAGE_TEXT2:"مراقبة وإدارة طلباتك عبر شركات شحن متعددة، مما يضمن التسليم الدقيق وفي الوقت المناسب من البداية إلى النهاية - الكل في لوحة تحكم واحدة",
    TRACKPAGE_TEXT3:"إبقاء عينيك على كل شحنة",
    TRACKPAGE_TEXT4:"ابق على اطلاع دائم بشحناتك، سواء تم تسليمها أو قيد المعالجة، من خلال التتبع في الوقت الفعلي. توفر shipra نظرة عامة سريعة أو رؤى تفصيلية حول حالة الشحنة الخاصة بك. يمكنك أيضًا إدارة المرتجعات بكفاءة، واتخاذ القرارات بشأن كيفية التعامل معها بسرعة",
    TRACKPAGE_TEXT5:"صفحة تتبع العلامات التجارية",
    TRACKPAGE_TEXT6:"عزز تجربة عملائك من خلال صفحة التتبع ذات العلامة التجارية Shpra. قم بتخصيص الصفحة باستخدام شعارك وألوان علامتك التجارية ورسائلك، مع الحفاظ على علامتك التجارية في المقدمة والوسط خلال العملية بأكملها. يمكن للعملاء متابعة شحناتهم في الوقت الفعلي، بينما تتاح لك الفرصة لعرض العروض الترويجية وتوصيات المنتجات، وتحويل التتبع إلى أداة مشاركة.",
    TRACKPAGE_TEXT7:"الإخطارات عبر الرسائل القصيرة، واتساب، والبريد الإلكتروني",
    TRACKPAGE_TEXT8:"أبقِ العملاء على اطلاع بإشعارات العلامة التجارية في الوقت الفعلي من خلال الرسائل القصيرة وWhatsApp والبريد الإلكتروني. تقوم Shipra تلقائيًا بإرسال التحديثات في كل مرحلة من مراحل عملية التسليم، مما يعزز الشفافية ورضا العملاء",
    TRACKPAGE_TEXT9:"تتبع الرؤى",
    TRACKPAGE_TEXT10:"سيساعدك الحصول على تحليل فوري لأداء التسليم وملاحظات التحصيل على زيادة ولاء العملاء بعد كل عملية شراء واتخاذ القرارات اللازمة لنمو إيرادات الأعمال على المدى الطويل.",
    MANAGEPAGE_TEXT1:"قم بتنظيم وإدارة عملية تنفيذ الطلب بالكامل بسهولة.",
    MANAGEPAGE_TEXT2:"تواصل مع شركات النقل و3PL، وقم بتبسيط عملية الشحن وإدارة المرتجعات والمزيد",
    MANAGEPAGE_TEXT3:"تخلص من مهام الشحن اليدوية.",
    MANAGEPAGE_TEXT4:"قم بتوصيل جميع قنوات المبيعات وشركاء الشحن لديك مع Shipra لمزامنة الطلبات تلقائيًا وإدارتها بشكل أسرع وأسهل وأكثر كفاءة",
    MANAGEPAGE_TEXT5:"حافظ على تكاليف التنفيذ الخاصة بك تحت السيطرة.",
    MANAGEPAGE_TEXT6:"تعمل الواجهة الموحدة للطلبات متعددة القنوات على تقليل الوقت والتكاليف والجهد الإداري ومعالجة الطلبات بشكل كبير. يمكن إكمال الإجراءات المجمعة ببضع نقرات فقط، بينما يتم تقليل المهام المتكررة بكفاءة",
    MANAGEPAGE_TEXT7:"إدارة المخزون الخاص بك بدقة استثنائية.",
    MANAGEPAGE_TEXT8:"احصل على رؤية كاملة لمخزونك بالكامل. قم بمزامنة المخزون الخاص بك لإبقائه محدثًا، وتقليل الأخطاء البشرية والتأكد من عدم نقص المخزون أبدًا. استفد من البيانات الثاقبة من تحليل موردي المنتجات، وحركة المبيعات، والطلب على المنتجات، والمنتجات عالية الأداء.",
    MANAGEPAGE_TEXT9:"تعزيز كفاءة فريقك.",
    MANAGEPAGE_TEXT10:"عزز عملية التنفيذ الخاصة بك وحقق نتائج أفضل من خلال سير العمل الأمثل. إنشاء وتخصيص سير العمل للاختيار والتعبئة. الوصول إلى حساب متعدد المستخدمين بمستويات أذونات قابلة للتخصيص. قم بإعداد مستخدمي دعم العملاء لإدارة الحساب بشكل مبسط",
    MANAGEPAGE_TEXT11:"أعد تعريف الطريقة التي تدير بها طلباتك.",
} 