import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
// import OtherPage from "../Pages/OtherPage"
const Index = React.lazy(() => import("../Pages/index"));
const Integration = React.lazy(() => import("../Pages/integration"));
const ContactUs = React.lazy(() => import("../Pages/contactUs"));
const Ship = React.lazy(() => import("../Pages/ship"));
const Track = React.lazy(() => import("../Pages/track"));
const Manage = React.lazy(() => import("../Pages/manage"));
const OnlineRetailer = React.lazy(() => import("../Pages/onlineRetailers"));
const Blog = React.lazy(() => import("../Pages/blogs"));
const CaseStudies = React.lazy(() => import("../Pages/caseStudies"));
const SingleBlog = React.lazy(() => import("../Pages/singleBlog"));
const SingleCaseStudy = React.lazy(() => import("../Pages/singleCaseStudy"));
const Features = React.lazy(() => import("../Pages/features"));
const Demo = React.lazy(() => import("../Pages/demo"));

const PrivacyPolicy = React.lazy(() => import("../Pages/privacyPolicy"));
const Carrier = React.lazy(() => import("../Pages/carrier"));
const CarrierKnowMore = React.lazy(() => import("../Pages/CarrierKnowMore"));
const KnowMoreDetails = React.lazy(() => import("../Pages/KnowMoreDetails"));
const OtherPage = React.lazy(() => import("../Pages/OtherPage"));
const SocialSeller = React.lazy(() => import("../Pages/socialSeller"));
const SignUpFree = React.lazy(() => import("../Pages/freeSignUp"));
const Pricing = React.lazy(() => import("../Pages/pricing"));

const MainRoutes = () => {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  function External() {
    window.location.href = "https://app.shipra.io";
    return null;
  }
  return (
    <div
      className={LanguageReducer.direction}
      style={{ direction: LanguageReducer.direction }}
    >
      <Routes>
        <Route path="/" element={<Index />}></Route>
        <Route path="/integration" element={<Integration />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/ship" element={<Ship />}></Route>
        <Route path="/track" element={<Track />}></Route>
        <Route path="/manage" element={<Manage />}></Route>
        <Route path="/online-retailers" element={<OnlineRetailer />}></Route>
        <Route path="/blogs" element={<Blog />}></Route>
        <Route
          path="/Leveraging-the-Power-of-3PL"
          element={<SingleBlog />}
        ></Route>
        <Route
          path="/The-Strategic-Synergy-Ecommerce"
          element={<SingleBlog />}
        ></Route>
        <Route
          path="/Why-Shipra-Revolutionizing"
          element={<SingleBlog />}
        ></Route>
        <Route path="/Last-Mile-Delivery" element={<SingleBlog />}></Route>
        <Route
          path="/cross-border-ecommerce-selling-with-shipra"
          element={<SingleBlog />}
        ></Route>
        <Route path="/how-temu-redefined-ecommerce-lessons-for-online-retailers" element={<SingleBlog />}></Route>
        <Route
          path="/reverse-logistics-supply-chain-management"
          element={<SingleBlog />}
        ></Route>
         <Route
          path="/understanding-incoterms-a-guide-for-global-trade"
          element={<SingleBlog />}
        ></Route>
        <Route path="/features" element={<Index />}></Route>
        <Route path="/demo" element={<Demo />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/carrier" element={<Carrier />}></Route>
        <Route path="/carrierKnowMore" element={<CarrierKnowMore />}></Route>
        <Route path="/knowMoreDetails" element={<KnowMoreDetails />}></Route>
        <Route path="/social-seller" element={<SocialSeller />}></Route>
        <Route path="/free-signup" element={<SignUpFree />}></Route>
        <Route path="/pricing" element={<Pricing />}></Route>
        <Route path="/login" element={<External />} />
        <Route path="/OtherPage" element={<OtherPage />} />
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route
          path="/Embellish-Gold-Optimizes-Order-Delivery-with-a-Hybrid-Approach-Using-Shipra"
          element={<SingleCaseStudy />}
        ></Route>
        <Route
          path="/MBY-Traders-Achieves-Phenomenal-Growth-in-Volume-&-Reduces-Delivery-Time-with-Shipra"
          element={<SingleCaseStudy />}
        ></Route>
      </Routes>
    </div>
  );
};

export default MainRoutes;
